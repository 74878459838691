import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import OneInputSelect from './OneInputSelect';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  }
}));

function SubcategoryInput(props) {
  const classes = useStyles();
  const [subcategories, setSubcategories] = useState([])
  const [loading, setLoading] = useState(false)
  
  async function getSubcategories() {
    if (props.category.value < 0) {
      return
    }

    setLoading(true)

    let results
    try {
      results = await axios.get(`/v1/subcategory/${props.category.value}`)
    } catch (err) {
      console.warn("Could not get subcategories", err)
      setLoading(false)
      return
    }
    
    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['subcategory_name'],
        value: result['subcategory_id']
      }
    })
    
    setSubcategories(normalizedResults)
    setLoading(false)
  }
  
  useEffect(() => {
    getSubcategories()
  }, [props.category])

  return (
    <Grid container>
      <Grid item xs={11}>
        <OneInputSelect
          selectValue={props.subcategory}
          textValue={props.subcategoryOther}
          field="subcategory"
          handleChange={props.setSubcategory}
          options={subcategories}
          disabled={!props.category.label}
          hideSelect={props.hideSelect}
          loading={loading}
          hint={props.showHint && "Note: This will create a new subcategory"}
        />
      </Grid>
      {
        !props.hideSelect && (
          <Grid item xs={1} className={classes.buttonAlign}>
            <RefreshButton onClick={getSubcategories} disabled={!props.category.label || loading} />
          </Grid>
        )
      }
    </Grid>
  );
}

export default SubcategoryInput;
