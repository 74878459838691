import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const states = ['', 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']

function CustomerLocationInput(props) {

  const handleChange = fieldName => (event) => {
    const fieldValue = event.target.value

    props.setCustomerLocation({
      ...props.customerLocation,
      [fieldName]: fieldValue
    })
  }

  return (
    <Grid container>
      <Grid item xs={5}>
        <TextField
          required={props.required}
          fullWidth
          label="Latitude"
          type="number"
          value={props.customerLocation.latitude}
          onChange={handleChange('latitude')}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <TextField
          required={props.required}
          fullWidth
          label="Longitude"
          type="number"
          value={props.customerLocation.longitude}
          onChange={handleChange('longitude')}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={11}>
        <TextField
          required={props.required}
          fullWidth
          label="Address"
          value={props.customerLocation.address}
          onChange={handleChange('address')}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required={props.required}
          fullWidth
          label="City"
          value={props.customerLocation.city}
          onChange={handleChange('city')}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <TextField
          select
          required={props.required}
          fullWidth
          label="State"
          value={props.customerLocation.state}
          onChange={handleChange('state')}
          SelectProps={{
            native: true,
          }}
          margin="normal"
          variant="outlined"
        >
          {
            states.map((option) => <option value={option} key={option}>{option}</option>)
          }
        </TextField>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <TextField
          required={props.required}
          fullWidth
          label="Zip Code"
          type="number"
          value={props.customerLocation.zip}
          onChange={handleChange('zip')}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

export default CustomerLocationInput;
