import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  SET_RESOLVE_CUSTOMER,
  SET_RESOLVE_CUSTOMER_CUSTOMER,
  REMOVE_POTENTIAL_CUSTOMER,
  SET_RESOLVE_CUSTOMER_LOCATION,
} from '../../store/actionTypes';
import SubmitButton from '../SubmitButton/SubmitButton';
import CustomerInput from '../InquirySelect/CustomerInput';
import axios from 'axios'
import CustomerLocationInput from '../InquirySelect/CustomerLocationInput';

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  }
}))

const mapStateToProps = (state) => {
  return {
    inquiryId: state.potentialAdditions.resolveCustomer.inquiryId,
    customer: state.potentialAdditions.resolveCustomer.customer,
    customerOther: state.potentialAdditions.resolveCustomer.customerOther,
    customerLocation: state.potentialAdditions.resolveCustomer.location,
    userId: state.auth.user.userId,
    userEmail: state.auth.user.email,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setResolveCustomer: (rowData) => {
      const { submitted_inquiry_id, location_name, customer_location_id, customer_name_text, customer_code_text, latitude, longitude } = rowData
      dispatch({
        type: SET_RESOLVE_CUSTOMER,
        inquiryId: submitted_inquiry_id,
        customer: { label: location_name ? location_name : "Other...", value: customer_location_id || -2 },
        customerOther: { name: customer_name_text, code: customer_code_text },
        latitude,
        longitude,
      })
    },
    setCustomer: (customer, isOther) => {
      dispatch({
        type: SET_RESOLVE_CUSTOMER_CUSTOMER,
        customer,
        isOther
      })
    },
    removeCustomer: (index) => {
      dispatch({
        type: REMOVE_POTENTIAL_CUSTOMER,
        index
      })
    },
    setCustomerLocation: (locationData) => {
      dispatch({
        type: SET_RESOLVE_CUSTOMER_LOCATION,
        locationData
      })
    }
  }
}

function ResolveCustomerForm(props) {
  const classes = useStyles();

  const handleSubmit = async () => {
    const { inquiryId, customer, customerOther, customerLocation, userEmail, userId } = props

    const customerData = {
      inquiryId,
      customer: customer.value >= 0 ? customer.value : customerOther,
      customerLocation,
    }

    try {
      await axios.post('/v1/locations/review', {
        customerData,
        createNewCustomer: customer.value === -2,
        reviewUser: userEmail,
        userId,
      })

      props.removeCustomer(props.rowIndex)
    } catch (err) {
      console.warn("Could not resolve customer", err)
    }

    props.onClose()
  }

  const handleDelete = async () => {
    try {
      await axios.post('/v1/locations/remove', {
        inquiryId: props.inquiryId,
        reviewUser: props.userEmail,
      })

      props.removeCustomer(props.rowIndex)
    } catch (err) {
      console.warn("Could not resolve customer", err)
    }

    props.onClose()
  }

  const isFormInvalid = () => {
    const { customer, customerOther, customerLocation } = props
    const { latitude, longitude, address, city, state, zip } = customerLocation

    if (customer.value === -1 || (customer.value === -2 && (customerOther.name === "" || customerOther.code === ""))) {
      return true
    }

    if (!latitude || !longitude || !address || !city || !state || !zip) {
      return true
    }

    return false
  }

  useEffect(() => {
    props.setResolveCustomer(props.data)
  }, [props.data])

  return (
    <>
      <CustomerInput customer={props.customer.label} customerOther={props.customerOther} userLocation={{latitude: props.data.latitude, longitude: props.data.longitude}} setCustomer={props.setCustomer} setLocation={() => { }} showHint hideDialog />
      <CustomerLocationInput customerLocation={props.customerLocation} setCustomerLocation={props.setCustomerLocation} />
      <SubmitButton noRedirect onClick={handleSubmit} className={classes.submitButton} disabled={isFormInvalid}>Approve Changes</SubmitButton>
      <SubmitButton noRedirect onClick={handleDelete} className={classes.submitButton} color="secondary">Delete</SubmitButton>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolveCustomerForm);
