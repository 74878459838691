import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import NetWeightInput from '../InquirySelect/NetWeightInput';
import SubmitButton from '../SubmitButton/SubmitButton';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios'
import CoreInquiryForm from '../CreateMatchInquiry/CoreInquiryForm';
import {
  SET_MATCH_INQUIRY_NET_WEIGHT,
  SET_MATCH_INQUIRY_UNIT_OF_MEASURE,
  SET_MATCH_INQUIRY_MANUFACTURED_IN_CANADA,
} from '../../store/actionTypes';
import { connect } from 'react-redux';
import {
  isFormInvalid as isCoreFormInvalid,
} from '../CreateMatchInquiry/sharedInquiryFunctions'

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  }
}))

const mapStateToProps = (state) => {
  return {
    newProduct: state.matchInquiryForm,
    netWeight: state.matchInquiryForm.netWeight,
    unitOfMeasure: state.matchInquiryForm.unitOfMeasure,
    manufacturedInCanada: state.matchInquiryForm.manufacturedInCanada,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNetWeight: (netWeight) => {
      dispatch({
        type: SET_MATCH_INQUIRY_NET_WEIGHT,
        netWeight
      })
    },
    setUnitOfMeasure: (unitOfMeasure) => {
      dispatch({
        type: SET_MATCH_INQUIRY_UNIT_OF_MEASURE,
        unitOfMeasure
      })
    },
    setManufacturedInCanada: (event) => {
      dispatch({
        type: SET_MATCH_INQUIRY_MANUFACTURED_IN_CANADA,
        manufacturedInCanada: event.target.checked
      })
    },
  }
}

function NewProductInputForm(props) {
  const classes = useStyles()

  const isFormInvalid = () => {
    const { category, subcategory, categoryOther, subcategoryOther, productOther, netWeight, unitOfMeasure } = props.newProduct

    if (isCoreFormInvalid(props.newProduct)) {
      return true
    }
    if (category.value === -2 && categoryOther === "") {
      return true
    }
    if (subcategory.value < 0 && subcategoryOther === "") {
      return true
    }
    if (props.isBakemark && productOther.code === "") {
      return true
    }
    if (productOther.name === "" || netWeight === "" || unitOfMeasure === "") {
      return true
    }
    return false
  }

  const handleSubmit = async () => {
    const { category, categoryOther, subcategory, subcategoryOther, flourDetails, competitor, competitorOther, productOther, netWeight, unitOfMeasure, manufacturedInCanada } = props.newProduct

    const newProductData = {
      category: category.value >= 0 ? category.value : categoryOther,
      subcategory: subcategory.value >= 0 ? subcategory.value : subcategoryOther,
      flourDetails,
      brand: competitor.value >= 0 ? competitor.value : competitorOther,
      ...productOther,
      netWeight,
      unitOfMeasure,
      manufacturedInCanada,
    }

    try {
      const apiUrl = props.isBakemark ? '/v1/bakemark-products' : '/v1/competitive-products'
      await axios.post(apiUrl, {
        newProduct: newProductData,
        createNewCategory: category.value === -2,
        createNewSubcategory: subcategory.value === -2,
        createNewBrand: competitor.value === -2
      })
    } catch (err) {
      console.warn(`Could not create ${props.field}`, err)
    }

    props.onSubmit(true)
  }

  return (
    <>
      <CoreInquiryForm isBakemark={props.isBakemark} hideProductSelect showHints/>
      <NetWeightInput netWeight={props.netWeight} unitOfMeasure={props.unitOfMeasure} setNetWeight={props.setNetWeight} setUnitOfMeasure={props.setUnitOfMeasure} required />
      {props.isBakemark && (
        <FormControlLabel
          control={
            <Checkbox
              checked={props.manufacturedInCanada}
              onChange={props.setManufacturedInCanada}
              color="primary"
            />
          }
          label="Manufactured in Canada 🇨🇦"
        />
      )}
      <SubmitButton noRedirect onClick={handleSubmit} className={classes.submitButton} disabled={isFormInvalid}>Create</SubmitButton>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewProductInputForm);
