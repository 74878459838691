import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: '-4px',
    marginTop: '20px'
  }
}));

function RefreshButton(props) {
  const classes = useStyles();

  return (
    <IconButton onClick={props.onClick} className={classes.button} disabled={props.disabled} aria-label="Refresh">
      <RefreshIcon />
    </IconButton>
  )
}

export default RefreshButton;
