export const SET_UAA_AUTH = 'SET_UAA_AUTH'
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export const SET_LOCATION = 'SET_LOCATION'

export const SET_MATCH_INQUIRY_ID = 'SET_MATCH_INQUIRY_ID'
export const SET_MATCH_INQUIRY_CATEGORY = 'SET_MATCH_INQUIRY_CATEGORY'
export const SET_MATCH_INQUIRY_SUBCATEGORY = 'SET_MATCH_INQUIRY_SUBCATEGORY'
export const SET_MATCH_INQUIRY_FLOUR_DETAILS = 'SET_MATCH_INQUIRY_FLOUR_DETAILS'
export const SET_MATCH_INQUIRY_COMPETITOR = 'SET_MATCH_INQUIRY_COMPETITOR'
export const SET_MATCH_INQUIRY_PRODUCT = 'SET_MATCH_INQUIRY_PRODUCT'
export const SET_MATCH_INQUIRY_CUSTOMER = 'SET_MATCH_INQUIRY_CUSTOMER'
export const SET_MATCH_INQUIRY_NET_WEIGHT = 'SET_MATCH_INQUIRY_NET_WEIGHT'
export const SET_MATCH_INQUIRY_UNIT_OF_MEASURE = 'SET_MATCH_INQUIRY_UNIT_OF_MEASURE'
export const SET_MATCH_INQUIRY_BAKEMARK_PRODUCTS = '  SET_MATCH_INQUIRY_REVIEW_NOTES'
export const SET_MATCH_INQUIRY_REVIEW_NOTES = 'SET_MATCH_INQUIRY_REVIEW_NOTES'
export const SET_MATCH_INQUIRY_PICTURES = 'SET_MATCH_INQUIRY_PICTURES'
export const SET_MATCH_INQUIRY_PRICE = 'SET_MATCH_INQUIRY_PRICE'
export const SET_MATCH_INQUIRY_MANUFACTURED_IN_CANADA = 'SET_MATCH_INQUIRY_MANUFACTURED_IN_CANADA'
export const SET_MATCH_INQUIRY_FORM = 'SET_MATCH_INQUIRY_FORM'
export const CLEAR_MATCH_INQUIRY_FORM = 'CLEAR_MATCH_INQUIRY_FORM'

export const SET_POTENTIAL_PRODUCTS = 'SET_POTENTIAL_PRODUCTS'
export const REMOVE_POTENTIAL_PRODUCT = 'REMOVE_POTENTIAL_PRODUCT'
export const SET_INCOMPLETE_INFORMATION = 'SET_INCOMPLETE_INFORMATION'
export const ADD_INCOMPLETE_INFORMATION = 'ADD_INCOMPLETE_INFORMATION'
export const REMOVE_INCOMPLETE_INFORMATION = 'REMOVE_INCOMPLETE_INFORMATION'
export const SET_POTENTIAL_CUSTOMERS = 'SET_POTENTIAL_CUSTOMERS'
export const REMOVE_POTENTIAL_CUSTOMER = 'REMOVE_POTENTIAL_CUSTOMER'
export const SET_POTENTIAL_PRICE_INQUIRIES = 'SET_POTENTIAL_PRICE_INQUIRIES'
export const REMOVE_POTENTIAL_PRICE_INQUIRY = 'REMOVE_POTENTIAL_PRICE_INQUIRY'

export const SET_RESOLVE_CUSTOMER = 'SET_RESOLVE_CUSTOMER'
export const SET_RESOLVE_CUSTOMER_CUSTOMER = 'SET_RESOLVE_CUSTOMER_CUSTOMER'
export const SET_RESOLVE_CUSTOMER_LOCATION = 'SET_RESOLVE_CUSTOMER_LOCATION'

export const SET_NEW_EQUIVALENT_PRODUCTS = 'SET_NEW_EQUIVALENT_PRODUCTS'
export const CLEAR_CREATE_NEW_DATA = 'CLEAR_CREATE_NEW_DATA'
