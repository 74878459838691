import React, { useState } from 'react';
import { Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  dropDownArrow: {
    verticalAlign: 'text-bottom'
  },
  menuItemText: {
    paddingRight: 12
  },
  headerText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  toolbar: {
    marginLeft: 'auto'
  },
}));

function UserAppBarMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function logout() {
    localStorage.removeItem("pendingInquiries");
    props.auth.userAgentApplication.logout();
    props.onLogout();
  }

  return (
    <>
      <Typography
        variant="h6"
        color="inherit"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={clsx(classes.toolbar, props.className)}
      >
        <span className={classes.headerText}>{props.auth.user.name || ""}</span>
        <ArrowDropDownIcon className={classes.dropDownArrow} />
      </Typography>
      <Menu 
        id="simple-menu" 
        anchorEl={anchorEl} 
        open={Boolean(anchorEl)} 
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {props.children}
        <MenuItem onClick={logout}>
          <span className={classes.menuItemText}>Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserAppBarMenu
