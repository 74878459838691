import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PictureInput from '../PictureInput/PictureInput';
import PicturePreview from '../PicturePreview/PicturePreview';
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  picInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  picPreview: {
    marginBottom: theme.spacing(0.5)
  }
}));

function PictureForm(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)

  const addPicture = async (base64Encoding, fileObject) => {
    setLoading(true)
    const formData = new FormData();
    formData.append('fileName', fileObject.name);
    formData.append('file', fileObject);
    try {
      var uploadImageResults = await axios.post(`/v1/pictures`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      props.setPictures(props.pictures.concat({
        render: base64Encoding,
        s3Key: uploadImageResults.data.Key
      }))
    } catch (err) {
      console.warn("Could not upload image,", err)
    }
    
    setLoading(false)
  }

  const removePicture = index => {
    props.setPictures([
      ...props.pictures.slice(0, index),
      ...props.pictures.slice(index + 1)
    ])
  };

  return (
    <>
      <Grid container justify={props.extraLarge ? "center" : "flex-start"}>
        <Grid item xs={props.extraLarge ? 6 : 10} className={classes.picInput}>
          <PictureInput onChange={addPicture} disabled={props.pictures.length > 2} required={props.required} loading={loading} extraLarge={props.extraLarge}>{props.children}</PictureInput>
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.picPreview}>
        {
          props.pictures.map((value, index) => (
            <Grid item xs={4} key={index}>
              <PicturePreview picture={value.render} onDelete={() => removePicture(index)} />
            </Grid>
          ))
        }
      </Grid>
    </>
  );
}
    
export default PictureForm
