import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  pictureRender: {
    width: '100%',
    height: '100%'
  },
  button: {
    background: theme.palette.primary
  },
  rightIcon: {
    marginLeft: theme.spacing(0.5),
  },
}));

function PicturePreview(props) {
  const classes = useStyles();

  return (
    <>
      {
        props.picture && (
          <div>
            <img src={props.picture} alt="Input File Preview" className={classes.pictureRender} />
            {
              props.onDelete !== undefined && (
                <Button fullWidth variant="contained" size="small" color="secondary" className={classes.button} onClick={props.onDelete}>
                  Remove<DeleteIcon className={classes.rightIcon} />
                </Button>
              )
            }
          </div>
        )
      }
    </>
  );
}

export default PicturePreview;
