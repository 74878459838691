import React, { useState } from 'react'
import BakemarkProductInput from './BakemarkProductInput';
import SubmitButton from '../SubmitButton/SubmitButton';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  autocompletePadding: {
    paddingBottom: theme.spacing(13),
  },
  submitButton: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  }
}));

const DeleteBakemarkProductForm = (props) => {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [checkbox, setCheckbox] = useState(false)

  const handleSubmit = async () => {
    try {
      await axios.post('/v1/bakemark-products/delete', {
        productIds: selected.map(item => item.value)
      })
    } catch (err) {
      console.warn("Could not delete BakeMark products", err)
    }

    props.onSubmit(true)
  }

  const isFormInvalid = () => {
    if (selected.length < 1 || !checkbox) {
      return true
    }
    return false
  }

  const handleCheckbox = (event) => {
    setCheckbox(event.target.checked)
  }

  return (
    <div className={classes.autocompletePadding}>
      <BakemarkProductInput
        bakemarkProducts={selected}
        setBakemarkProducts={setSelected}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkbox}
            onChange={handleCheckbox}
          />
        }
        label="I understand deleting products is permanent and CANNOT be undone"
      />
      <SubmitButton noRedirect onClick={handleSubmit} className={classes.submitButton} disabled={isFormInvalid}>Delete</SubmitButton>
    </div>
  )
}

export default DeleteBakemarkProductForm
