import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import OneInputSelect from './OneInputSelect';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  }
}));

function CompetitorInput(props) {
  const classes = useStyles();
  const [competitors, setCompetitors] = useState([])
  const [loading, setLoading] = useState(false)

  async function getCompetitors() {
    setLoading(true)

    let results
    try {
      results = await axios.get('/v1/competitors')
    } catch (err) {
      console.warn("Could not get competitors", err)
      setLoading(false)
      return
    }
    
    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['competitive_brand_name'],
        value: result['competitive_brand_id']
      }
    })

    setCompetitors(normalizedResults)
    setLoading(false)
  }

  useEffect(() => {
    getCompetitors();
  }, []) // props.subcategory if using that as input

  return (
    <Grid container>
      <Grid item xs={11}>
        <OneInputSelect
          selectValue={props.competitor}
          textValue={props.competitorOther}
          field="brand"
          handleChange={props.setCompetitor}
          options={competitors}
          loading={loading}
          hint={props.showHint && "Note: This will create a new competitive brand"}
          showTopCompetitiveBrands
        />
      </Grid>
      <Grid item xs={1} className={classes.buttonAlign}>
        <RefreshButton onClick={getCompetitors} disabled={loading}/>
      </Grid>
    </Grid>
  );
}

export default CompetitorInput;
