import React from 'react';
import { TextField } from '@material-ui/core';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

// SQL query to get top competitive brands: SELECT `competitive_brand_id`, COUNT(*) from submitted_inquiries WHERE competitive_brand_id IS NOT NULL GROUP BY competitive_brand_id ORDER BY COUNT(*) DESC
const topCompetitiveBrands = ["Dawn Foods", "Pillsbury/GM", "Puratos", "R & H", "Caravan"]
// SQL query to get top BakeMark brands: SELECT `bakemark_brand_id`, COUNT(*) from bakemark_products WHERE bakemark_brand_id IS NOT NULL GROUP BY bakemark_brand_id ORDER BY COUNT(*) DESC
const topBakemarkBrands = ["Westco", "Best Brands", "Multifoods", "Bakesense", "Sprnklna"]

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  var spaces = s.replace(/([A-Z])/g, " $1");
  return spaces.charAt(0).toUpperCase() + spaces.slice(1)
}

function OneInputSelect(props) {
  const { selectValue, textValue, field, handleChange, options, disabled, hideSelect } = props;
  const allOptions = [
    {
      label: "",
      value: -1,
    },
    ...options,
    {
      label: "Other...",
      value: -2
    }
  ];

  function getObjectByLabel(label) {
    for (let i = 0; i < allOptions.length; i++) {
      if (allOptions[i].label === label) {
        return allOptions[i];
      }
    }

    return { label: "Error: Couldn't get object for label", value: -1 };
  }

  function handleSelectChange(event) {
    let fieldValue = event.target.value;
    let newObj = getObjectByLabel(fieldValue);
    handleChange(newObj, false);
  }

  function handleTextFieldChange(event) {
    let fieldValue = event.target.value;
    handleChange(fieldValue, true);
  }
  
  return (
    <>
      {
        !hideSelect && (
          <LoadingIndicator loading={props.loading}>
            <TextField
              select
              required
              fullWidth
              disabled={disabled || props.loading}
              label={capitalize(field)}
              value={selectValue}
              onChange={handleSelectChange}
              SelectProps={{
                native: true,
              }}
              margin="normal"
              variant="outlined"
            >
              {props.showTopCompetitiveBrands || props.showTopBakemarkBrands ? (
                <>
                  <option value=""></option>
                  <optgroup label="Most common">
                    {props.showTopCompetitiveBrands && topCompetitiveBrands.map(brand => (
                      <option value={brand} key={brand}>{brand}</option>
                    ))}
                    {props.showTopBakemarkBrands && topBakemarkBrands.map(brand => (
                      <option value={brand} key={brand}>{brand}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Alphabetical">
                    {allOptions.slice(1).map((option, index) => (
                      <option key={index} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </optgroup>
                </>
              ) : (
                allOptions.map((option, index) => (
                  <option key={index} value={option.label}>
                    {option.label}
                  </option>
                ))
              )}
            </TextField>
          </LoadingIndicator>
        )
      }
      {
        (selectValue === "Other..." || hideSelect) && (
          <TextField
            required
            fullWidth
            label={capitalize(field)}
            value={textValue}
            onChange={handleTextFieldChange}
            helperText={props.hint}
            margin="normal"
            variant="outlined"
            // inputProps={{
            //   maxlength: 128
            // }}
          />
        )
      }
    </>
  )
}

export default OneInputSelect;
