import axios from 'axios';
import { 
  createInquirySubmission as startInquirySubmission,
  isFormInvalid as isCoreFormInvalid, 
} from '../CreateMatchInquiry/sharedInquiryFunctions'
import { writeInquiryToLS } from '../CreateMatchInquiry/matchInquiryFunctions'

export const createInquirySubmission = (inquiryForm, authUser, userLocation) => {
  const partialInquiryData = startInquirySubmission(inquiryForm, authUser, userLocation)

  return {
    ...partialInquiryData,
    customerId: null,
    customerNameText: null,
    customerCodeText: null,
    netWeight: null,
    unitOfMeasure: null,
  }
}

export async function submitInquiry(inquiryForm, authUser, userLocation, setInquiryId) {
  let newPath = "/"
  let newState = { event: "PRICE_INQUIRY_SUBMITTED"}
  let connectionError = false
  const submissionData = createInquirySubmission(inquiryForm, authUser, userLocation)
  
  try {
    var createInquiryResults = await axios.post('/v1/inquiry/price', {
      inquiryData: submissionData
    })
    setInquiryId(createInquiryResults.data.insertId)
  } catch (err) {
    console.warn("Could not submit inquiry,", err)
    connectionError = err.toString().includes("Network")
    writeInquiryToLS(inquiryForm, userLocation)
  }

  if (connectionError) {
    newPath = "/"
    newState = { event: "CONNECTION_ERROR" }
  }

  return {
    newPath,
    newState
  }
}

export function isFormInvalid(inquiryForm, isBasicForm) {
  const { price, pictures } = inquiryForm;

  if(isBasicForm) {
    if (pictures.length === 0) {
      return true
    }
  } else {
    if (isCoreFormInvalid(inquiryForm)) {
      return true
    }
  }
  if (price === "") {
    return true
  }

  return false
}
