import React from 'react';
import DrawerAppBar from '../DrawerAppBar/DrawerAppBar'
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import ManageMatchInquiries from '../ManageMatchInquiries/ManageMatchInquiries';
import PotentialAdditions from '../PotentialAdditions/PotentialAdditions';
import EquivalentProducts from '../EquivalentProducts/EquivalentProducts';
import ManagePriceInquiries from '../ManagePriceInquiries/ManagePriceInquiries'
import { Redirect, Switch } from 'react-router-dom'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  fullHeightTable: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '4px',
  },
}));

function ManagementUI(props) {
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.fullHeightTable}>
      <DrawerAppBar />
      <Switch>
        <PrivateRoute path="/manage/match-inquiries" component={ManageMatchInquiries} managersOnly/>
        <PrivateRoute path="/manage/additions" component={PotentialAdditions} managersOnly/>
        <PrivateRoute path="/manage/equivalent-products" component={EquivalentProducts} managersOnly/>
        <PrivateRoute path="/manage/price-inquiries" component={ManagePriceInquiries} managersOnly/>
        <Redirect exact from="/manage" to={{ pathname: "/manage/additions", state: props.location.state }} />
        <Redirect exact from="/manage/submitted" to={{ pathname: "/manage/match-inquiries", state: props.location.state }} />
      </Switch>
    </Grid>
  );
}

export default ManagementUI
