import React, { useState } from 'react';
import { Typography, Card, CardActions, CardContent, Button, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { submitInquiry, formatInquiryForDisplay } from '../CreateMatchInquiry/matchInquiryFunctions';
import { connect } from 'react-redux'
import { SET_MATCH_INQUIRY_ID, SET_MATCH_INQUIRY_FORM } from '../../store/actionTypes';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  subText: {
    fontWeight: 400
  },
  marginTop: {
    marginTop: theme.spacing(0.5)
  }
}));

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    inquiryForm: state.matchInquiryForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setInquiryId: (inquiryId) => {
      dispatch({
        type: SET_MATCH_INQUIRY_ID,
        inquiryId
      })
    },
    setInquiryForm: (inquiryForm) => {
      dispatch({
        type: SET_MATCH_INQUIRY_FORM,
        inquiryForm
      })
    }
  }
}

function InquiryCard(props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const formattedInquiry = formatInquiryForDisplay(props.inquiry)

  const handleSubmit = async () => {
    setLoading(true)
    props.setInquiryForm(props.inquiry)
    const { newPath, newState } = await submitInquiry(props.inquiry, props.authUser, props.inquiry.userLocation, props.setInquiryId)
    window.scrollTo(0, 0)
    setLoading(false)
    props.onDelete(props.inquiry)
    props.history.push({ pathname: newPath || "/", state: newState })
  }

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">
            {formattedInquiry.product.label} {formattedInquiry.product.manufacturedInCanada ? <Chip label="🇨🇦 Manufactured in Canada" variant="outlined" size="small"/> : ""}
          </Typography>
          {
            formattedInquiry.product.code && (
              <Typography variant="h6" className={classes.subText}>
                #{formattedInquiry.product.code}
              </Typography>
            )
          }
          <Typography variant="h6" className={classes.subText}>
            {formattedInquiry.competitor.label}
          </Typography>
        </CardContent>
        {
          props.showActions && (
            <CardActions>
              <Button size="small" color="inherit" onClick={() => props.onDelete(props.inquiry)} disabled={loading}>
                Delete
              </Button>
              <LoadingIndicator loading={loading} vertShift="small">
                <Button size="small" color="primary" onClick={handleSubmit} disabled={loading}>
                  Retry
                </Button>
              </LoadingIndicator>
            </CardActions>
          )
        }
      </Card>
    </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InquiryCard));
