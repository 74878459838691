import React from 'react';
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core';
import BakeMarkLogo from '../../assets/BakeMarkLogo.png';
import { makeStyles } from '@material-ui/styles';
import UserAppBar from '../UserAppBar/UserAppBar';
import SubmitButton from '../SubmitButton/SubmitButton';
import { withRouter } from 'react-router-dom';
import { CLEAR_MATCH_INQUIRY_FORM } from '../../store/actionTypes';

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: 'center'
  },
  bakeMarkImg: {
    width: '100%',
    marginTop: theme.spacing(3.5),
  },
  mainTextSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.7),
  },
  paragraphSpacing: {
    marginTop: theme.spacing(0.7),
    marginBottom: theme.spacing(0.7),
  },
  lastParagraphSpacing: {
    marginBottom: theme.spacing(1),
  }
}));

const mapDispatchToProps = (dispatch) => {
  return {
    clearInquiryForm: () => {
      dispatch({
        type: CLEAR_MATCH_INQUIRY_FORM
      })
    },
  }
}

function InquiryHome(props) {
  const classes = useStyles();

  return (
    <>
      <UserAppBar />
      <Grid container alignItems="center">
        <Grid item>
          <Grid container justify="center">
            <Grid item xs={10} sm={9} lg={8}>
              <img src={BakeMarkLogo} alt="BakeMark logo" className={classes.bakeMarkImg} />
            </Grid>
          </Grid>
          <Grid container className={classes.centerAlign} justify="center">
            <Grid item xs={12} sm={7} lg={6}>
              <p className={classes.mainTextSpacing}>Welcome to Compass!</p>
              <p className={classes.paragraphSpacing}>Your sales tool for unlocking new opportunities and growing the business.</p>
              <p className={classes.lastParagraphSpacing}>Tap below to get started.</p>
              <SubmitButton onClick={props.clearInquiryForm} path="/inquiry/match/new">Competitive Match</SubmitButton>
              <SubmitButton onClick={props.clearInquiryForm} path="/inquiry/price/new">Price Inquiry</SubmitButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(connect(null, mapDispatchToProps)(InquiryHome))
