import React, { useState } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import ManagementUI from '../ManagementUI/ManagementUI';
import SalespersonUI from '../SalespersonUI/SalespersonUI';
import NotFound404Page from '../NotFound404Page/NotFound404Page';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import Login from '../Login/Login';
import EnableLocation from '../EnableLocation/EnableLocation';
import { makeStyles } from '@material-ui/styles';
import { Grid, DialogContentText } from '@material-ui/core';
import axios from 'axios'
import InfoDialog from '../InfoDialog/InfoDialog';
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';
import InvalidAccount from '../InvalidAccount/InvalidAccount';

const useStyles = makeStyles(theme => ({
  containerSpacing: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingTop: '72px', // 56+16
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '80px', // 64+16
    },
    height: "100%",
  },
}));

function AppRouter(props) {
  const classes = useStyles();
  const [event, setEvent] = useState(null)
  const [dialog, setDialog] = useState(false)

  function handleClose() {
    setDialog(false);
  }

  axios.interceptors.response.use(null, (error) => {
    if (error.toString().includes("Network")) {
      setDialog(true)
    } else if (error.response.status === 500 || error.response.status === 404) {
      setEvent("INTERNAL_SERVER_ERROR")
    } else if (error.response.status === 502) {
      setEvent("BAD_GATEWAY")
    }
    return Promise.reject(error);
  });
  
  return (
    <>
      <Grid container justify="center" className={classes.containerSpacing}>
        <Switch>
          <Redirect from="/inquiry/new" to="/inquiry/match/new" exact/>
          <Redirect from="/inquiry/home" to="/inquiry" exact/>
          <PrivateRoute path="/manage" component={ManagementUI} managersOnly/>
          <PrivateRoute path="/inquiry" component={SalespersonUI} />
          <Route path="/login" component={Login} />
          <Route path="/invalid-account" component={InvalidAccount} />
          <PrivateRoute path="/enable-location" component={EnableLocation} />
          <Redirect exact from="/" to={{ pathname: "/inquiry", state: props.location.state }} />
          <Route component={NotFound404Page} />
        </Switch>
      </Grid>
      <InfoDialog open={dialog} onClose={handleClose} title="No internet connection" rightButton="Ok">
        <DialogContentText>
          Please check internet connection and try again
        </DialogContentText>
      </InfoDialog>
      <ColoredSnackbar open={Boolean(event)} onClose={() => setEvent(null)} event={event}/>
    </>
  );
}

export default withRouter(AppRouter);
