import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  SET_MATCH_INQUIRY_FORM,
  SET_MATCH_INQUIRY_NET_WEIGHT,
  SET_MATCH_INQUIRY_UNIT_OF_MEASURE,
  SET_MATCH_INQUIRY_REVIEW_NOTES,
  SET_MATCH_INQUIRY_PRICE,
} from '../../store/actionTypes';
import NetWeightInput from '../InquirySelect/NetWeightInput';
import ReviewNotesInput from '../ReviewNotesInput/ReviewNotesInput';
import SubmitButton from '../SubmitButton/SubmitButton';
import axios from 'axios'
import CoreInquiryForm from '../CreateMatchInquiry/CoreInquiryForm';
import { isFormInvalid as isCoreFormInvalid } from '../CreateMatchInquiry/sharedInquiryFunctions'
import PriceInput from '../InquirySelect/PriceInput';

const useStyles = makeStyles(theme => ({
  submitButton: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  }
}))

const mapStateToProps = (state) => {
  return {
    inquiryForm: state.matchInquiryForm,
    inquiryId: state.matchInquiryForm.inquiryId,
    netWeight: state.matchInquiryForm.netWeight,
    unitOfMeasure: state.matchInquiryForm.unitOfMeasure,
    price: state.matchInquiryForm.price,
    reviewNotes: state.matchInquiryForm.reviewNotes,
    user: state.auth.user.email,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setResolveProduct: (rowData) => {
      const { submitted_inquiry_id, category_id, category_name, category_text, subcategory_id, subcategory_name, subcategory_text, protein, ash, bleached, enriched, malted, ascorbic_acid, enzymes, bromated, competitive_brand_id, competitive_brand_name, competitive_brand_text, competitive_product_id, product_name, competitive_product_name_text, competitive_product_code_text, net_weight, competitive_product_net_weight_text, unit_of_measure, competitive_product_unit_of_measure_text, review_notes, price } = rowData
      dispatch({
        type: SET_MATCH_INQUIRY_FORM,
        inquiryForm: {
          inquiryId: submitted_inquiry_id,
          category: { label: category_name ? category_name : "Other...", value: category_id || -2 },
          categoryOther: category_text ? category_text : "",
          subcategory: { label: subcategory_name ? subcategory_name : "Other...", value: subcategory_id || -2 },
          subcategoryOther: subcategory_text ? subcategory_text : "",
          flourDetails: {
            protein,
            ash,
            bleached,
            enriched,
            malted,
            ascorbicAcid: ascorbic_acid,
            enzymes,
            bromated,
          },
          competitor: { label: competitive_brand_name ? competitive_brand_name : "Other...", value: competitive_brand_id || -2 },
          competitorOther: competitive_brand_text ? competitive_brand_text : "",
          product: { label: product_name ? product_name : "Other...", value: competitive_product_id || -2 },
          productOther: { name: competitive_product_name_text, code: competitive_product_code_text },
          netWeight: net_weight ? net_weight : competitive_product_net_weight_text ? competitive_product_net_weight_text : "",
          unitOfMeasure: unit_of_measure ? unit_of_measure : competitive_product_unit_of_measure_text ? competitive_product_unit_of_measure_text : "",
          price: price ? price : "",
          reviewNotes: review_notes ? review_notes : "",
        }
      })
    },
    setNetWeight: (netWeight) => {
      dispatch({
        type: SET_MATCH_INQUIRY_NET_WEIGHT,
        netWeight
      })
    },
    setUnitOfMeasure: (unitOfMeasure) => {
      dispatch({
        type: SET_MATCH_INQUIRY_UNIT_OF_MEASURE,
        unitOfMeasure
      })
    },
    setPrice: (price) => {
      dispatch({
        type: SET_MATCH_INQUIRY_PRICE,
        price
      })
    },
    setReviewNotes: (reviewNotes) => {
      dispatch({
        type: SET_MATCH_INQUIRY_REVIEW_NOTES,
        reviewNotes
      })
    },
  }
}

function ResolvePriceInquiryForm(props) {
  const classes = useStyles()

  const handleSubmit = async () => {
    const { inquiryId, category, categoryOther, subcategory, subcategoryOther, flourDetails, competitor, competitorOther, product, productOther, netWeight, unitOfMeasure, reviewNotes } = props.inquiryForm

    const inquiryData = {
      inquiryId,
      category: category.value >= 0 ? category.value : categoryOther,
      subcategory: subcategory.value >= 0 ? subcategory.value : subcategoryOther,
      flourDetails,
      brand: competitor.value >= 0 ? competitor.value : competitorOther,
      product: product.value >= 0 ? product.value : productOther,
      netWeight,
      unitOfMeasure,
      reviewNotes,
      reviewUser: props.user,
    }

    try {
      await axios.post('/v1/inquiry/price/review', {
        inquiryData,
        createNewCategory: category.value === -2,
        createNewSubcategory: subcategory.value === -2,
        createNewBrand: competitor.value === -2,
        createNewProduct: product.value === -2,
      })
    } catch (err) {
      console.warn("Could not submit price inquiry review", err)
    }

    props.onClose()
  }

  const handleDelete = async () => {
    try {
      await axios.post('/v1/inquiry/remove', {
        inquiryId: props.inquiryId,
        reviewNotes: props.reviewNotes,
        reviewUser: props.user,
      })
    } catch (err) {
      console.warn("Could not submit product review delete", err)
    }
    props.onClose()
  }

  const isFormInvalid = () => {
    const { category, subcategory, categoryOther, subcategoryOther, netWeight, unitOfMeasure } = props.inquiryForm

    if (isCoreFormInvalid(props.inquiryForm)) {
      return true
    }
    if (category.value === -2 && categoryOther === "") {
      return true
    }
    if (subcategory.value < 0 && subcategoryOther === "") {
      return true
    }
    if ((netWeight !== "" && unitOfMeasure === "") || (unitOfMeasure !== "" && netWeight === "")) {
      return true
    }

    return false
  }

  useEffect(() => {
    props.setResolveProduct(props.data)
  }, [props.data])

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <CoreInquiryForm showHints/>
          <NetWeightInput netWeight={props.netWeight} unitOfMeasure={props.unitOfMeasure} setNetWeight={props.setNetWeight} setUnitOfMeasure={props.setUnitOfMeasure} required />
          <PriceInput required price={props.price} setPrice={props.setPrice} />
          <ReviewNotesInput reviewNotes={props.reviewNotes} onChange={props.setReviewNotes} />
        </Grid>
        <Grid item xs={12}>
          <SubmitButton noRedirect onClick={handleSubmit} className={classes.submitButton} disabled={isFormInvalid}>Submit</SubmitButton>
          <SubmitButton noRedirect onClick={handleDelete} className={classes.submitButton} color="secondary">Delete</SubmitButton>
        </Grid>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolvePriceInquiryForm)
