import React from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';

function PriceInput(props) {
  const handleChange = event => {
    props.setPrice(event.target.value)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          required={props.required}
          fullWidth
          label="Price"
          type="number"
          value={props.price}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default PriceInput
