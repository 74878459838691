import React from 'react';
import axios from 'axios';
import qs from 'querystring'
import { SET_POTENTIAL_PRODUCTS, SET_INCOMPLETE_INFORMATION, SET_POTENTIAL_CUSTOMERS, SET_POTENTIAL_PRICE_INQUIRIES } from '../../store/actionTypes';
import { connect } from 'react-redux'
import MoreInfoDialog from '../MoreInfoDialog/MoreInfoDialog';
import { IconButton, Grid, Typography } from '@material-ui/core';
import { PersonPinCircle } from '@material-ui/icons';
import ResolveRowDialog from '../ResolveRowDialog/ResolveRowDialog';
import ResolvePriceRowDialog from '../ResolveRowDialog/ResolvePriceRowDialog';
import PicturesDialog from '../PicturesDialog/PicturesDialog';
import PotentialAdditionsTable from './PotentialAdditionsTable';

const potentialProductColumns = [
  {
    width: 300,
    flexGrow: 1.0,
    label: 'Product Name',
    dataKey: 'product_name',
    cellContentRenderer: ({ rowData, cellData }) => {
      return cellData ? cellData : <span style={{ fontStyle: 'italic' }}>{rowData.competitive_product_name_text}</span>
    }
  },
  {
    width: 130,
    label: 'Product Code',
    dataKey: 'product_code',
    numeric: true,
    cellContentRenderer: ({ rowData, cellData }) => {
      return cellData ? cellData : <span style={{ fontStyle: 'italic' }}>{rowData.competitive_product_code_text}</span>
    }
  },
  {
    width: 210,
    label: 'User',
    dataKey: 'last_name',
    cellContentRenderer: ({ rowData }) => {
      return `${rowData.branch_id === 0 ? "[Add branch] " : ""}${rowData.last_name}, ${rowData.first_name}`
    }
  },
  {
    width: 230,
    label: 'Actions',
    dataKey: 'submitted_inquiry_id',
    cellContentRenderer: ({ rowData, rowIndex }) => (
      <>
        <MoreInfoDialog data={rowData} />
        <PicturesDialog submittedInquiryId={rowData.submitted_inquiry_id} hide={rowData.picture_id}/>
        <ResolveRowDialog data={rowData} rowIndex={rowIndex} isProduct/>
      </>
    )
  },
]

const potentialCustomerColumns = [
  {
    width: 200,
    flexGrow: 1.0,
    label: 'Customer Name',
    dataKey: 'name',
    cellContentRenderer: ({ rowData, cellData }) => {
      return cellData ? cellData : <span style={{ fontStyle: 'italic' }}>{rowData.customer_name_text}</span>
    }
  },
  {
    width: 120,
    label: 'Customer Code',
    dataKey: 'number',
    numeric: true,
    cellContentRenderer: ({ rowData, cellData }) => {
      return cellData ? cellData : <span style={{ fontStyle: 'italic' }}>{rowData.customer_code_text}</span>
    }
  },
  {
    width: 100,
    label: 'Location',
    dataKey: 'latitude',
    cellContentRenderer: ({ rowData }) => {
      return rowData.latitude ? (
        <IconButton href={`https://www.google.com/maps/search/${rowData.latitude},${rowData.longitude}`} target="_blank" rel="noopener">
          <PersonPinCircle />
        </IconButton>
      ) : null
    }
  },
  {
    width: 200,
    label: 'Actions',
    dataKey: 'submitted_inquiry_id',
    cellContentRenderer: ({ rowData, rowIndex }) => (
      <>
        <MoreInfoDialog data={rowData} />
        <ResolveRowDialog data={rowData} rowIndex={rowIndex} />
      </>
    )
  },
]

const priceInquiryColumns = [
  {
    width: 100,
    label: 'Price',
    dataKey: 'price',
    numeric: true,
    cellContentRenderer: ({ cellData }) => {
      return cellData ? `$${cellData}` : ""
    }
  },
  {
    width: 210,
    flexGrow: 1.0,
    label: 'User',
    dataKey: 'last_name',
    cellContentRenderer: ({ rowData }) => {
      return `${rowData.branch_id === 0 ? "[Add branch] " : ""}${rowData.last_name}, ${rowData.first_name}`
    }
  },
  {
    width: 230,
    label: 'Actions',
    dataKey: 'submitted_inquiry_id',
    cellContentRenderer: ({ rowData, rowIndex }) => (
      <>
        <MoreInfoDialog data={rowData} />
        <PicturesDialog submittedInquiryId={rowData.submitted_inquiry_id} hide={rowData.picture_id} />
        <ResolvePriceRowDialog data={rowData} rowIndex={rowIndex}/>
      </>
    )
  },
]

const mapStateToProps = (state) => {
  return {
    productData: state.potentialAdditions.productData,
    incompleteInformationData: state.potentialAdditions.incompleteInformationData,
    customerData: state.potentialAdditions.customerData,
    priceInquiryData: state.potentialAdditions.priceInquiryData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setProductData: (productData) => {
      dispatch({
        type: SET_POTENTIAL_PRODUCTS,
        productData
      })
    },
    setIncompleteInformationData: (incompleteInformationData) => {
      dispatch({
        type: SET_INCOMPLETE_INFORMATION,
        incompleteInformationData
      })
    },
    setCustomerData: (customerData) => {
      dispatch({
        type: SET_POTENTIAL_CUSTOMERS,
        customerData
      })
    },
    setPriceInquiryData: (priceInquiryData) => {
      dispatch({
        type: SET_POTENTIAL_PRICE_INQUIRIES,
        priceInquiryData
      })
    },
  }
}

function PotentialAdditions(props) {
  const fetchProductAdditions = async (startIndex, count) => {
    const results  = await axios.get('/v1/inquiry?' + qs.stringify({
      pendingProducts: true,
      inquiryType: 0,
      startIndex,
      count,
    }))
    return results.data['array']
  }
  
  const fetchIncompleteInformation = async (startIndex, count) => {
    const results = await axios.get('/v1/inquiry?' + qs.stringify({
      incompleteInformation: true,
      inquiryType: 0,
      startIndex,
      count,
    }))
    return results.data['array']
  }
  
  const fetchCustomerAdditions = async (startIndex, count) => {
    const results = await axios.get('/v1/inquiry?' + qs.stringify({
      pendingCustomers: true,
      inquiryType: 0,
      startIndex,
      count,
    }))
    return results.data['array']
  }
  
  const fetchPriceInquiryAdditions = async (startIndex, count) => {
    const results = await axios.get('/v1/inquiry?' + qs.stringify({
      pendingProducts: true,
      inquiryType: 1,
      startIndex,
      count,
    }))
    return results.data['array']
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h4" gutterBottom>
          Competitive Match Inquiries
        </Typography>
        <PotentialAdditionsTable
          rowData={props.productData}
          setRowData={props.setProductData}
          columns={potentialProductColumns}
          fetchPotentialAdditions={fetchProductAdditions}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" gutterBottom>
          Price Inquiries
        </Typography>
        <PotentialAdditionsTable
          rowData={props.priceInquiryData}
          setRowData={props.setPriceInquiryData}
          columns={priceInquiryColumns}
          fetchPotentialAdditions={fetchPriceInquiryAdditions}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" gutterBottom>
          Incomplete Information
        </Typography>
        <PotentialAdditionsTable
          rowData={props.incompleteInformationData}
          setRowData={props.setIncompleteInformationData}
          columns={potentialProductColumns}
          fetchPotentialAdditions={fetchIncompleteInformation}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" gutterBottom>
          Potential Customers
        </Typography>
        <PotentialAdditionsTable
          rowData={props.customerData}
          setRowData={props.setCustomerData}
          columns={potentialCustomerColumns}
          fetchPotentialAdditions={fetchCustomerAdditions}
        />
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PotentialAdditions)
