import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import FlourDetailsCheckboxInputs from './FlourDetailsCheckboxInputs';

function FlourDetails(props) {

  const handleTextChange = fieldName => (event) => {
    props.setData({
      ...props.data,
      [fieldName]: event.target.value
    })
  }

  const handleCheckboxChange = fieldName => (event) => {
    props.setData({
      ...props.data,
      [fieldName]: event.target.checked
    })
  }

  return (
    <>
      <Grid container>
        <Grid item xs={5}>
          <TextField
            required
            fullWidth
            label="Protein"
            type="number"
            value={props.data.protein}
            onChange={handleTextChange('protein')}
            margin="normal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={5}>
          <TextField
            required
            fullWidth
            label="Ash"
            type="number"
            value={props.data.ash}
            onChange={handleTextChange('ash')}
            margin="normal"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <FlourDetailsCheckboxInputs data={props.data} onChange={handleCheckboxChange}/>
    </>
  );
}

export default FlourDetails;
