import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  var spaces = s.replace(/([A-Z])/g, " $1");
  return spaces.charAt(0).toUpperCase() + spaces.slice(1)
}

function TwoInputSelect(props) {
  const { selectValue, textValueForName, textValueForCode, field, handleChange, options, disabled, hideSelect } = props;
  const allOptions = [
    {
      label: "",
      value: -1,
    },
    ...options || [],
    {
      label: "Other...",
      value: -2
    }
  ];

  function getObjectByLabel(label) {
    for (let i = 0; i < allOptions.length; i++) {
      if (allOptions[i].label === label) {
        return allOptions[i];
      }
    }

    return { label: "Error: Couldn't get object for label", value: -1 };
  }


  function handleSelectChange(event) {
    let fieldValue = event.target.value;
    let newObj = getObjectByLabel(fieldValue);
    handleChange(newObj, false);
  }

  const handleTextFieldChange = textFieldName => event => {
    let fieldValue = event.target.value;
    let newObj = textFieldName === "Code" ? {
      code: fieldValue,
      name: textValueForName
    } : {
      code: textValueForCode,
      name: fieldValue
    }
    
    handleChange(newObj, true);
  }

  return (
    <>
      {
        !hideSelect && (
          <LoadingIndicator loading={props.loading}>
            <TextField
              select
              required
              fullWidth
              disabled={disabled || props.loading}
              label={capitalize(field) + " Name"}
              value={selectValue}
              onChange={handleSelectChange}
              SelectProps={{
                native: true,
              }}
              margin="normal"
              variant="outlined"
            >
              {allOptions.map((option, index) => (
                <option key={index} value={option.label}>
                  {option.label}
                  {option.code ? " (" + option.code + ")" : ""}
                </option>
              ))}
            </TextField>
          </LoadingIndicator>
        )
      }
      {
        (selectValue === "Other..." || hideSelect) && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                label={capitalize(field) + " Name"}
                value={textValueForName}
                onChange={handleTextFieldChange("Name")}
                helperText={props.hint}
                margin="normal"
                variant="outlined"
                // inputProps={{
                //   maxlength: 128
                // }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label={capitalize(field) + " Code"}
                value={textValueForCode}
                onChange={handleTextFieldChange("Code")}
                margin="normal"
                variant="outlined"
                type="number"
                // inputProps={{
                //   maxlength: 50
                // }}
              />
            </Grid>
          </Grid>
        )
      }
    </>
  )
}

export default TwoInputSelect;
