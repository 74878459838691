import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { Grid, Chip } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';
import AutosuggestAutocomplete from './AutosuggestAutocomplete';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  },
  chip: {
    marginTop: '.5rem',
    marginRight: '.5rem',
  },
}));

function CompetitiveProductInput(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  async function getProducts() {
    setLoading(true)

    let results
    try {
      results = await axios.get('/v1/competitive-products/all')
    } catch (err) {
      console.warn("Could not get competitive products", err)
      setLoading(false)
      return
    }

    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['product_name'],
        value: result['competitive_product_id'],
        code: result['product_code'],
        brand: result['competitive_brand_name'],
      }
    })

    setProducts(normalizedResults)
    setLoading(false)
  }

  const handleInputChange = (newItem) => {
    const index = props.competitiveProducts.findIndex(obj => obj.value === newItem.value);
    if (index !== -1) {
      return
    }
    const newSelected = [...props.competitiveProducts, newItem]
    props.setCompetitiveProducts(newSelected)
  }

  const removeChip = (item) => () => {
    const index = props.competitiveProducts.findIndex(obj => obj.value === item.value);
    const newSelected = [
      ...props.competitiveProducts.slice(0, index),
      ...props.competitiveProducts.slice(index + 1)
    ]
    props.setCompetitiveProducts(newSelected)
  }

  useEffect(() => {
    getProducts();
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        {props.competitiveProducts.map((item) => (
          <Chip key={item.value} onDelete={removeChip(item)} label={`${item.label} (${item.brand}${item.code ? `, ${item.code}` : ""})`}  className={classes.chip} />
        ))}
      </Grid>
      <Grid item xs={11}>
        <AutosuggestAutocomplete
          suggestions={products}
          onChange={handleInputChange}
          disabled={loading || (props.disabled && props.disabled())}
          field={props.label || "Competitive Product"}
          loading={loading}
        />
      </Grid>
      <Grid item xs={1} className={classes.buttonAlign}>
        <RefreshButton onClick={getProducts} disabled={loading} />
      </Grid>
    </Grid>
  );
}

export default CompetitiveProductInput;
