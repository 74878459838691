import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/styles';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function ColoredSnackbar(props) {
  const classes = useStyles();
  let variant, message = "";

  switch (props.event) {
    case "ADDITIONAL_INFO_SUBMITTED":
      variant = "success";
      message = "Submission received, you will be emailed after it is reviewed";
      break;
    case "LOCATION_GRANTED":
      variant = "success";
      message = "Location granted";
      break;
    case "LOCATION_DENIED":
      variant = "error";
      message = "Location is required";
      break;
    case "LOGIN_REQUIRED":
      variant = "info";
      message = "Please login";
      break;
    case "LOGIN_SUCCESSFUL":
      variant = "info";
      message = `Logged in as ${props.user || " "}`;
      break;
    case "UNAUTHORIZED_USER":
      variant = "warning";
      message = "Please use a bakemark.com account";
      break;
    case "NO_MANAGER_PERMISSION":
      variant = "error";
      message = "You don't have permission to access the Management UI";
      break;
    case "INTERNAL_SERVER_ERROR":
      variant = "error";
      message = "Sorry, there was a bug in the app";
      break;
    case "BAD_GATEWAY":
      variant = "error";
      message = "Sorry, maintenance update in progress. Please try again later.";
      break;
    case "CONNECTION_ERROR":
      variant = "warning";
      message = (
        <>
          {"An error occurred"}
          <br />
          &nbsp;&bull;&nbsp;{"Check internet connection"}
        </>
      );
      break;
    case "ROW_RESOLVED":
      variant = "success";
      message = "Row reviewed successfully";
      break;
    case "MANAGE_SUCCESS":
      variant = "success";
      message = "Created/deleted successfully";
      break;
    case "NO_DATA_TO_EXPORT":
      variant = "warning";
      message = "There is no data for the selected dates";
      break;
    case "PRICE_INQUIRY_SUBMITTED":
      variant = "success";
      message = "Price inquiry submitted, thank you";
      break;
    default:
      variant = "error";
      message = "Please ignore this popup";
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    // Clear router location state to prevent snackbar from popping up again
    props.history.push(props.history.location.pathname)
    props.onClose();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={props.open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <SnackbarContentWrapper
        onClose={handleClose}
        variant={variant}
        className={classes.margin}
        message={message}
      />
    </Snackbar>
  );
}

export default withRouter(ColoredSnackbar);
