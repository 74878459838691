import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import TextAppBar from '../TextAppBar/TextAppBar';
import SubmitButton from '../SubmitButton/SubmitButton';
import CustomerInput from '../InquirySelect/CustomerInput';
import { submitInquiry, isMainFormInvalid } from './matchInquiryFunctions'
import { SET_MATCH_INQUIRY_ID, SET_MATCH_INQUIRY_CUSTOMER, SET_LOCATION } from '../../store/actionTypes';
import CoreInquiryForm from './CoreInquiryForm';

const useStyles = makeStyles(theme => ({
  mainButton: {
    marginTop: theme.spacing(0.5),
  }
}));

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    inquiryForm: state.matchInquiryForm,
    customer: state.matchInquiryForm.customer.label,
    customerOther: state.matchInquiryForm.customerOther,
    userLocation: state.userLocation,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setInquiryId: (inquiryId) => {
      dispatch({
        type: SET_MATCH_INQUIRY_ID,
        inquiryId
      })
    },
    setCustomer: (customer, isOther) => {
      dispatch({
        type: SET_MATCH_INQUIRY_CUSTOMER,
        customer,
        isOther
      })
    },
    setLocation: (position) => {
      const { latitude, longitude } = position.coords
      dispatch({
        type: SET_LOCATION,
        latitude,
        longitude
      })
    },
  }
}

function CreateMatchInquiry(props) {
  const classes = useStyles()

  const verifyForm = () => {
    return isMainFormInvalid(props.inquiryForm)
  }

  const handleSubmit = async () => {
    return await submitInquiry(props.inquiryForm, props.authUser, props.userLocation, props.setInquiryId)
  }

  return (
    <>
      <TextAppBar showBackArrow>New Competitive Match</TextAppBar>
      <CoreInquiryForm/>
      <CustomerInput customer={props.customer} customerOther={props.customerOther} userLocation={props.userLocation} setCustomer={props.setCustomer} setLocation={props.setLocation}/>
      <SubmitButton onClick={handleSubmit} disabled={verifyForm} className={classes.mainButton}>Submit</SubmitButton>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMatchInquiry);
