import React from 'react';
import { Button } from '@material-ui/core';

const emailTemplates = [
  {
    key: "match",
    subject: "Successful Match",
    text: `Hi {Senders Name},\n\nThank you for using the BakeMark App and submitting the information on the competitive product "{Competitive Product}" that had no match. After further review, it has been identified that the product(s) that BakeMark offers to match "{Competitive Product}" are: {Bakemark Products} \nNote, you will still need to check availability, but we hope this helps with gaining new business with the customer.\n\nPlease feel free to email ryan.mccoy@bakemark.com if you have any further questions.`,
  },
  {
    key: "no-match",
    subject: "No Match at this Time",
    text: `Hi {Senders Name},\n\nThank you for using the BakeMark App and submitting the information on the competitive product "{Competitive Product}" that had no match. After further review, we have found that at this time, BakeMark does not currently have a match to "{Competitive Product}". You may check with your branch buyer to see if they can source a like-item from another supplier. However, if you believe there is a significant opportunity with developing a BakeMark product to match, please let us know what the estimated volume (lbs) per month would for this particular request and we can consider a new project request.\n\nPlease feel free to email ryan.mccoy@bakemark.com if you have any further questions.`,
  },
  {
    key: "incomplete-information",
    subject: "Need More Information",
    text: `Hi {Senders Name},\n\nThank you for using the BakeMark App and submitting the information on the competitive product "{Competitive Product}" that had no match. In order to help identify the best possible BakeMark matches, we are going to need some more information. If you could please provide the following:\n- Picture (with a clear shot of the label)\n- Pack Size\n- Product Code\nAlso, if you can provide a spec sheet or a sample, that will also be helpful.\n\nPlease feel free to email ryan.mccoy@bakemark.com if you have any further questions.`,
  },
]

const getCompetitiveProductStr = (data) => {
  let returnStr = (data.competitive_brand_name || data.competitive_brand_text) + " " + (data.product_name || data.competitive_product_name_text)
  if (data.competitive_product_code_text) {
    returnStr += " (" + data.competitive_product_code_text + ")"
  }
  return returnStr
}

const getBakemarkProductsStr = (bmProducts) => {
  if (!bmProducts) return "An error occurred in the application"
  
  return bmProducts.map(product => {
    return `\n- ${product.brand} ${product.label} ${product.code ? `(${product.code})` : ""}`
  })
}

function EmailTemplateButton(props) {
  const templateObj = emailTemplates.find(x => x.key === props.templateKey) || {}
  const templateData = {
    "{Senders Name}": `${props.data.first_name} ${props.data.last_name}`,
    "{Competitive Product}": getCompetitiveProductStr(props.data),
    "{Bakemark Products}": getBakemarkProductsStr(props.products),
  }

  function insertVariables(templateStr = "") {
    Object.keys(templateData).forEach((key) => {
      var re = new RegExp(key, 'g');
      templateStr = templateStr.replace(re, templateData[key])
    })
    return templateStr
  }

  return (
    <Button variant="contained" color="primary" onClick={props.onClick} target="_blank" rel="noopener" href={`mailto:${encodeURIComponent(props.data.user_email)}?subject=${encodeURIComponent(templateObj.subject)}&body=${encodeURIComponent(insertVariables(templateObj.text))}`}>Email</Button>
  );
}

export default EmailTemplateButton
