import React, { useState, useEffect } from 'react';
import DataTable from '../DataTable/DataTable';
import axios from 'axios';
import qs from 'querystring'
import moment from 'moment'
import MoreInfoDialog from '../MoreInfoDialog/MoreInfoDialog';
import { IconButton } from '@material-ui/core';
import { PersonPinCircle } from '@material-ui/icons';
import ExportInquiries from '../ExportInquiries/ExportInquiries';
import PicturesDialog from '../PicturesDialog/PicturesDialog';

const submittedInquiryColumns = [
  {
    width: 180,
    label: 'Category',
    dataKey: 'category_name',
  },
  {
    width: 180,
    label: 'Subcategory',
    dataKey: 'subcategory_name',
  },
  {
    width: 140,
    label: 'Brand Name',
    dataKey: 'competitive_brand_name',
  },
  {
    width: 300,
    flexGrow: 1.0,
    label: 'Product Name',
    dataKey: 'product_name',
  },
  {
    width: 130,
    label: 'Product Code',
    dataKey: 'product_code',
    numeric: true,
  },
  {
    width: 110,
    label: 'Net Weight',
    dataKey: 'net_weight',
    cellContentRenderer: ({ rowData, cellData }) => {
      return cellData ? `${rowData.net_weight} ${rowData.unit_of_measure}` : ""
    }
  },
  {
    width: 200,
    label: 'Customer Name',
    dataKey: 'name',
  },
  {
    width: 120,
    label: 'Customer Code',
    dataKey: 'number',
    numeric: true,
  },
  {
    width: 100,
    label: 'Price',
    dataKey: 'price',
    numeric: true,
    cellContentRenderer: ({ cellData }) => {
      return cellData ? `$${cellData}` : ""
    }
  },
  {
    width: 100,
    label: 'Location',
    dataKey: 'latitude',
    cellContentRenderer: ({ rowData }) => {
      return rowData.latitude ? (
        <IconButton href={`https://www.google.com/maps/search/${rowData.latitude},${rowData.longitude}`} target="_blank" rel="noopener">
          <PersonPinCircle />
        </IconButton>
      ) : null
    }
  },
  {
    width: 200,
    label: 'Date',
    dataKey: 'submitted_datetime',
    cellContentRenderer: ({ cellData }) => {
      return moment.utc(cellData).local().format('MMM Do YYYY, h:mm a')
    }
  },
  {
    width: 210,
    label: 'User',
    dataKey: 'last_name',
    cellContentRenderer: ({ rowData }) => {
      return `${rowData.branch_id === 0 ? "[Add branch] " : ""}${rowData.last_name}, ${rowData.first_name}`
    }
  },
  {
    width: 130,
    label: 'More Info',
    dataKey: 'submitted_inquiry_id',
    cellContentRenderer: ({ rowData }) => (
      <>
        <MoreInfoDialog data={rowData} />
        <PicturesDialog submittedInquiryId={rowData.submitted_inquiry_id} hide={rowData.picture_id} />
      </>
    )
  },
]

function ManagePriceInquiries(props) {
  const [loading, setLoading] = useState(false)
  const [fullyLoaded, setFullyLoaded] = useState(false)
  const [priceInquiryData, setPriceInquiryData] = useState([])
  const [sortData, setSortData] = useState({
    sortBy: "submitted_datetime",
    sortDirection: "DESC"
  })

  const mapPriceData = (rawData) => {
    return rawData.map((result) => {
      const {
        category_name,
        subcategory_name,
        competitive_brand_name,
        product_name,
        product_code,
        net_weight,
        unit_of_measure,
        price,
        submitted_datetime,
        first_name,
        last_name,
        user_email,
        user_title,
        branch_name,
        product_review_status_text,
      } = result

      return {
        "Category": category_name,
        "Subcategory": subcategory_name,
        "Competitor": competitive_brand_name,
        "Product Name": product_name,
        "Product Code": product_code,
        "Net Weight": net_weight,
        "Unit of Measure": unit_of_measure,
        "Price": price,
        "Submitted Date": submitted_datetime,
        "User First Name": first_name,
        "User Last Name": last_name,
        "User Email": user_email,
        "User Title": user_title,
        "User Branch": branch_name,
        "Review Status": product_review_status_text,
      }
    })
  }

  const getPriceInquiries = async (startIndex, count) => {
    if (loading || fullyLoaded) {
      return
    }

    setLoading(true)

    let results
    try {
      results = await axios.get('/v1/inquiry?' + qs.stringify({
        inquiryType: 1,
        startIndex,
        count,
        ...sortData,
      }))
    } catch (err) {
      console.warn("Could not get submitted inquiries", err)
      setLoading(false)
      return
    }

    const normalizedResults = results.data['array']

    if (startIndex === 0) {
      setPriceInquiryData(normalizedResults)
    } else {
      setPriceInquiryData(priceInquiryData.concat(normalizedResults))
    }
    setLoading(false)

    if (normalizedResults.length !== count) {
      setFullyLoaded(true)
    }
  }

  const getRow = ({ index }) => {
    if (index < priceInquiryData.length - 1) {
      return priceInquiryData[index]
    } else if (index >= priceInquiryData.length - 1) {
      getPriceInquiries(priceInquiryData.length, 20)
      return priceInquiryData[index]
    }
  }

  const handleSort = (sortBy, sortDirection) => {
    setFullyLoaded(false)
    setSortData({ sortBy, sortDirection })
    setPriceInquiryData([])
  }

  useEffect(() => {
    getPriceInquiries(0, 20)
  }, [sortData.sortBy, sortData.sortDirection])

  return (
    <>
      <DataTable
        rowCount={priceInquiryData.length} 
        rowGetter={getRow} 
        loading={loading} 
        sortData={sortData} 
        onSort={handleSort} 
        columns={submittedInquiryColumns} />
      <ExportInquiries inquiryType={1} mapExportData={mapPriceData}/>
    </>
  );
}

export default ManagePriceInquiries
