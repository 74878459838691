import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  arrowIcon: {
    marginLeft: '4px',
    marginRight: '8px'
  },
  headerText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
}));

function TextAppBar(props) {
  const classes = useStyles(props);

  return (
    <AppBar position="fixed" color="primary">
      <Grid container justify="center">
        <Grid item xs={12} sm={11} md={10} lg={9} xl={6}>
          <Toolbar disableGutters={props.showBackArrow}>
            {
              props.showBackArrow && (
                <IconButton color="inherit" className={classes.arrowIcon} onClick={() => props.history.goBack()}>
                  <ArrowBackIcon/>
                </IconButton>
              )
            }
            <Typography
              variant="h6"
              color="inherit"
              className={classes.headerText}
            >
              {props.children}
            </Typography>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default withRouter(TextAppBar);
