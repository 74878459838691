import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import TextAppBar from '../TextAppBar/TextAppBar';
import InquiryCard from '../InquiryCard/InquiryCard';
import { List, ListSubheader, ListItem } from '@material-ui/core';
import InquiryExpansionPanel from '../InquiryExpansionPanel/InquiryExpansionPanel';
import axios from 'axios';
import qs from 'querystring';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const useStyles = makeStyles(theme => ({}))

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
  }
}

function InquiryHistory(props) {
  const classes = useStyles();
  const [pendingInquiries, setPendingInquiries] = useState((localStorage.pendingInquiries && JSON.parse(localStorage.pendingInquiries)) || [])
  const [matchInquiries, setMatchInquiries] = useState([])
  const [priceInquiries, setPriceInquiries] = useState([])
  const [loading, setLoading] = useState(false)
  
  async function getSubmittedInquiries() {
    setLoading(true)
    try {
      var results = await axios.get('/v1/inquiry?' + qs.stringify({
        userId: props.userId
      }))

      let matchInquiriesList = []
      let priceInquiriesList = []
      results.data['array'].forEach((arrItem) => {
        const formattedItem = {
          id: arrItem['submitted_inquiry_id'],
          product: {
            label: arrItem['product_name'] || arrItem['competitive_product_name_text'],
            code: arrItem['product_code'] || arrItem['competitive_product_code_text'],
            id: arrItem['competitive_product_id']
          },
          brand: {
            label: arrItem['competitive_brand_name'] || arrItem['competitive_brand_text']
          },
          date: arrItem['submitted_datetime']
        }

        if (arrItem['inquiry_type'] === 0) {
          matchInquiriesList.push(formattedItem)
        } else if (arrItem['inquiry_type'] === 1) {
          priceInquiriesList.push(formattedItem)
        }
      })

      setMatchInquiries(matchInquiriesList)
      setPriceInquiries(priceInquiriesList)
    } catch (err) {
      console.warn("Could not get inquiry history,", err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubmittedInquiries()
  }, [pendingInquiries])
  
  const deletePendingInquiry = (object) => {
    const inquiriesPending = (localStorage.pendingInquiries && JSON.parse(localStorage.pendingInquiries)) || []
    const index = inquiriesPending.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    
    const slicedInquiries = [
      ...inquiriesPending.slice(0, index),
      ...inquiriesPending.slice(index + 1)
    ]

    localStorage.pendingInquiries = JSON.stringify(slicedInquiries)
    setPendingInquiries(slicedInquiries)
  }
  
  return (
    <>
      <TextAppBar showBackArrow>Inquiry History</TextAppBar>
      <List subheader={<ListSubheader>Pending</ListSubheader>} className={classes.root}>
        {
          pendingInquiries.map((inquiry, index) => (
            <InquiryCard inquiry={inquiry} showActions onDelete={deletePendingInquiry} key={index} />
          ))
        }
        {
          pendingInquiries && pendingInquiries.length === 0 && (
            <ListItem>No pending inquiries</ListItem>
          )
        }
      </List>
      <List subheader={<ListSubheader>Competitive Match Inquiries</ListSubheader>} className={classes.root}>
        <LoadingIndicator loading={loading}>
          <InquiryExpansionPanel inquiries={matchInquiries} />
        </LoadingIndicator>
      </List>
      {
        matchInquiries.length === 0 && !loading && (
          <ListItem>No competitive match inquiries</ListItem>
        )
      }
      <List subheader={<ListSubheader>Price Inquiries</ListSubheader>} className={classes.root}>
        <LoadingIndicator loading={loading}>
          <InquiryExpansionPanel inquiries={priceInquiries} />
        </LoadingIndicator>
      </List>
      {
        priceInquiries.length === 0 && !loading && (
          <ListItem>No price inquiries</ListItem>
        )
      }
    </>
  );
}

export default connect(mapStateToProps)(InquiryHistory);
