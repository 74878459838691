import React, { useState } from 'react';
import InfoDialog from '../InfoDialog/InfoDialog';
import { Button } from '@material-ui/core';
import ResolveProductForm from '../ResolveForms/ResolveProductForm';
import { connect } from 'react-redux';
import { CLEAR_MATCH_INQUIRY_FORM, REMOVE_POTENTIAL_PRODUCT, ADD_INCOMPLETE_INFORMATION, REMOVE_INCOMPLETE_INFORMATION } from '../../store/actionTypes';
import ResolveCustomerForm from '../ResolveForms/ResolveCustomerForm';
import EmailTemplateButton from '../EmailTemplateButton/EmailTemplateButton';

const mapDispatchToProps = (dispatch) => {
  return {
    clearResolveForm: () => {
      dispatch({
        type: CLEAR_MATCH_INQUIRY_FORM
      })
    },
    addIncompleteInformation: (newIncompleteInformation) => {
      dispatch({
        type: ADD_INCOMPLETE_INFORMATION,
        newIncompleteInformation
      })
    },
    removeFromPotentialProduct: (index) => {
      dispatch({
        type: REMOVE_POTENTIAL_PRODUCT,
        index
      })
    },
    removeFromIncompleteInformation: (index) => {
      dispatch({
        type: REMOVE_INCOMPLETE_INFORMATION,
        index
      })
    },
  }
}

function ResolveRowDialog(props) {
  const [open, setOpen] = useState(false)
  const [templateKey, setTemplateKey] = useState(null)
  const [products, setProducts] = useState(null)

  function handleProductClose(optionalTemplateKey, optionalProducts) {
    if (optionalTemplateKey === "no-response") {
      props.removeFromIncompleteInformation(props.rowIndex)
    } else {
      setTemplateKey(optionalTemplateKey)
    }
    setProducts(optionalProducts)
    props.clearResolveForm()
    setOpen(false);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleEmailButtonClick() {
    if (templateKey === "incomplete-information") {
      props.addIncompleteInformation({
        ...products[0],
        product_review_status: 3
      })
    }

    if (!products || products[0].product_review_status !== 3) {
      props.removeFromPotentialProduct(props.rowIndex)
    } else {
      props.removeFromIncompleteInformation(props.rowIndex)
    }

    setTemplateKey(null)
  }

  return (
    <>
      {templateKey ? (
        <EmailTemplateButton templateKey={templateKey} products={products} data={props.data} onClick={handleEmailButtonClick}/>
      ) : (
        <Button variant="outlined" onClick={handleOpen}>Review</Button>
      )}
      <InfoDialog open={open} onClose={handleClose} title="Review row" rightButton="Cancel">
        {props.isProduct ? (
          <ResolveProductForm data={props.data} rowIndex={props.rowIndex} onClose={handleProductClose} hideIncompleteInformation={props.data.product_review_status === 3}/>
        ) : (
          <ResolveCustomerForm data={props.data} rowIndex={props.rowIndex} onClose={handleClose}/>
        )}
      </InfoDialog>
    </>
  );
}

export default connect(null, mapDispatchToProps)(ResolveRowDialog)
