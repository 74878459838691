import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { CameraAlt as CameraIcon } from '@material-ui/icons'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  imgInput: {
    display: 'none'
  },
  extraLarge: {
    paddingTop: '1.75rem',
    paddingBottom: '1.75rem',
  },
  iconSpacing: {
    marginRight: theme.spacing(.75),
    verticalAlign: 'middle',
  },
}));

function PictureInput(props) {
  const classes = useStyles();
  let fileInput = React.createRef();

  const readFile = () => {
    if (fileInput.current && fileInput.current.files[0]) {
      var reader = new FileReader();
      
      reader.onload = function (e) {
        props.onChange(e.target.result, fileInput.current.files[0])
      };
      
      reader.readAsDataURL(fileInput.current.files[0]);
    }
  }

  return (
    <LoadingIndicator loading={props.loading} vertShift="small">
      <Button fullWidth variant="contained" size="large" color="primary" disabled={props.disabled || props.loading} className={props.extraLarge && classes.extraLarge}>
        <label onChange={readFile}>
          <CameraIcon className={clsx(!props.extraLarge && classes.iconSpacing)}/>
          {props.extraLarge && (<br/>)}
          {props.children}
          {props.required && "*"}
          <input type="file" accept="image/*" className={classes.imgInput} ref={fileInput}/>
        </label>
      </Button>
    </LoadingIndicator>
  )
}

export default PictureInput;
