import React from 'react';
import { Grid, TextField } from '@material-ui/core';

function ReviewNotesInput(props) {

  const handleChange = (e) => {
    props.onChange(e.target.value)
  }
  
  return (
    <Grid container>
      <Grid item xs={11}>
        <TextField
          label="Review Notes"
          multiline
          fullWidth
          rows="3"
          rowsMax="4"
          value={props.reviewNotes}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

export default ReviewNotesInput;
