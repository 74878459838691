import React, { useState, useEffect } from 'react';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import InquiryHome from '../InquiryHome/InquiryHome';
import CreateMatchInquiry from '../CreateMatchInquiry/CreateMatchInquiry';
import CreatePriceInquiry from '../CreatePriceInquiry/CreatePriceInquiry';
import InquiryResults from '../InquiryResults/InquiryResults';
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo';
import InquiryHistory from '../InquiryHistory/InquiryHistory';
import { Grid } from '@material-ui/core';
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';
import { withRouter, Redirect } from 'react-router-dom';
import { useCurrentPosition } from 'react-use-geolocation';
import { SET_LOCATION } from '../../store/actionTypes';
import { connect } from 'react-redux'

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (position) => {
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude
      dispatch({
        type: SET_LOCATION,
        latitude,
        longitude
      })
    },
  }
}

function SalespersonUI(props) {
  const [routerState, setRouterState] = useState(props.location.state || {});
  const [open, setOpen] = useState(Object.keys(routerState).length !== 0); // set open to true if routerState did not evaluate to {}
  const [position, error] = useCurrentPosition();
  const [wasPrompted, setWasPrompted] = useState(false);

  useEffect(() => {
    if (position) {
      props.setLocation(position)
    }

    if (position && wasPrompted) {
      setRouterState({ event: "LOCATION_GRANTED" });
      setOpen(true);
    }
  }, [position])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWasPrompted(true);
    }, 1000)
    return () => {
      clearTimeout(timeout);
    }
  }, [])

  if (error) return <Redirect to="/enable-location" />;

  return (
    <>
      <Grid item xs={12} sm={11} md={10} lg={9} xl={6}>
        <PrivateRoute path="/inquiry" exact component={InquiryHome} />
        <PrivateRoute path="/inquiry/match/new" component={CreateMatchInquiry} />
        <PrivateRoute path="/inquiry/price/new" component={CreatePriceInquiry} />
        <PrivateRoute path="/inquiry/match/results" component={InquiryResults} />
        <PrivateRoute path="/inquiry/match/more-info" component={AdditionalInfo} />
        <PrivateRoute path="/inquiry/history" component={InquiryHistory} />
      </Grid>
      <ColoredSnackbar open={open} onClose={() => setOpen(false)} event={routerState.event} user={routerState.user} />
    </>
  );
}

export default withRouter(connect(null, mapDispatchToProps)(SalespersonUI))
