import {
  SET_UAA_AUTH,
  LOGIN,
  LOGOUT,
} from '../actionTypes';

const authDefaultState = {
  isAuthenticated: false,
  isBakeMarkAccount: false,
  isManager: false,
  user: {},
  userAgentApplication: null,
}

const authReducer = (state = authDefaultState, action) => {
  switch (action.type) {
    case SET_UAA_AUTH: 
      return {
        ...state,
        userAgentApplication: action.userAgentApplication
      }
    case LOGIN:
      return {
        ...state,
        ...action.auth
      }
    case LOGOUT:
      return authDefaultState
    default:
      return state;
  }
}

export default authReducer
