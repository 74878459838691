import React, { useState, useEffect } from 'react';
import DataTable from '../DataTable/DataTable';

function PotentialAdditionsTable(props) {
  const [loading, setLoading] = useState(false)
  const [fullyLoaded, setFullyLoaded] = useState(false)

  const getPotentialAdditions = async (startIndex, count) => {
    if (loading || fullyLoaded) {
      return
    }

    setLoading(true)

    let results
    try {
      results = await props.fetchPotentialAdditions(startIndex, count)
    } catch (err) {
      console.warn("Could not get potential additions", err)
      setLoading(false)
      return
    }

    if (startIndex === 0) {
      props.setRowData(results)
    } else {
      props.setRowData(props.rowData.concat(results))
    }

    setLoading(false)

    if (results.length !== count) {
      setFullyLoaded(true)
    }
  }

  const getRow = ({ index }) => {
    const data = props.rowData
    if (index < data.length - 1) {
      return data[index]
    } else if (index >= data.length - 1) {
      getPotentialAdditions(data.length, 10)
      return data[index]
    }
  }

  useEffect(() => {
    getPotentialAdditions(0, 10)
  }, [])

  return (
    <DataTable
      rowCount={props.rowData.length}
      rowGetter={getRow}
      loading={loading}
      columns={props.columns}
    />
  )
}

export default PotentialAdditionsTable
