import React, { useState } from 'react';
import { connect } from 'react-redux';
import SubmitButton from '../SubmitButton/SubmitButton';
import TextAppBar from '../TextAppBar/TextAppBar';
import PictureForm from '../PictureForm/PictureForm';
import PriceInput from '../InquirySelect/PriceInput';
import { submitInquiry, isFormInvalid } from './priceInquiryFunctions'
import { SET_MATCH_INQUIRY_ID, SET_MATCH_INQUIRY_PRICE, SET_MATCH_INQUIRY_PICTURES } from '../../store/actionTypes';
import CoreInquiryForm from '../CreateMatchInquiry/CoreInquiryForm';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  formTypeToggle: {
    textDecoration: 'underline',
    marginLeft: 'auto',
  },
  formTypeToggleWrapper: {
    textAlign: 'center',
    padding: '.5rem',
  },
}));

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    inquiryForm: state.matchInquiryForm,
    pictures: state.matchInquiryForm.pictures,
    price: state.matchInquiryForm.price,
    userLocation: state.userLocation,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setInquiryId: (inquiryId) => {
      dispatch({
        type: SET_MATCH_INQUIRY_ID,
        inquiryId
      })
    },
    setPrice: (price) => {
      dispatch({
        type: SET_MATCH_INQUIRY_PRICE,
        price
      })
    },
    setPictures: (pictures) => {
      dispatch({
        type: SET_MATCH_INQUIRY_PICTURES,
        pictures
      })
    },
  }
}

function CreatePriceInquiry(props) {
  const classes = useStyles()
  const [basicForm, setBasicForm] = useState(true)
  
  const verifyForm = () => {
    return isFormInvalid(props.inquiryForm, basicForm)
  }

  const handleSubmit = async () => {
    return await submitInquiry(props.inquiryForm, props.authUser, props.userLocation, props.setInquiryId)
  }

  return (
    <>
      <TextAppBar showBackArrow>New Price Inquiry</TextAppBar>
      {basicForm && <PictureForm extraLarge required pictures={props.pictures} setPictures={props.setPictures}>Add picture</PictureForm>}
      <div className={classes.formTypeToggleWrapper}>
        <span className={classes.formTypeToggle} onClick={() => setBasicForm(!basicForm)}>{basicForm ? "No picture available" : "Use picture instead"}</span>
      </div>
      {!basicForm && <CoreInquiryForm />}
      {basicForm && <p>Please provide CLEAR picture showing full label, including brand, pack size, and ingredient statement)</p>}
      <PriceInput required price={props.price} setPrice={props.setPrice}/>
      <SubmitButton onClick={handleSubmit} disabled={verifyForm}>Submit</SubmitButton>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePriceInquiry);
