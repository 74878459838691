import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import TwoInputSelect from './TwoInputSelect';
import axios from 'axios';
import { Grid, DialogContentText} from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';
import qs from 'querystring';
import InfoDialog from '../InfoDialog/InfoDialog';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  }
}));

function CustomerInput(props) {
  const classes = useStyles()
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)

  function handleClose() {
    props.setCustomer({
      label: "Other...",
      value: -2
    }, false)
    setAlertOpen(false);
  }
  
  function getLocation() {
    navigator.geolocation.getCurrentPosition(props.setLocation)
  }

  async function getCustomers() {
    setLoading(true)

    const { latitude, longitude } = props.userLocation
    if (latitude === undefined || longitude === undefined) {
      return
    }
    
    let results
    try {
      results = await axios.get('/v1/locations?' + qs.stringify({
        latitude,
        longitude
      }))
    } catch (err) {
      console.warn("Could not get customer locations", err)
      setLoading(false)
      return
    }

    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['location_name'],
        value: result['customer_location_id'],
        code: result['location_number']
      }
    })

    if (normalizedResults.length === 0 && !props.hideDialog) {
      setAlertOpen(true)
    }

    setCustomers(normalizedResults)
    setLoading(false)
  }

  useEffect(() => {
    getCustomers()
  }, [props.userLocation.latitude, props.userLocation.longitude])
  
  useEffect(() => {
    getLocation()
  }, [])

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <TwoInputSelect
            selectValue={props.customer}
            textValueForName={props.customerOther.name}
            textValueForCode={props.customerOther.code}
            field="customer"
            handleChange={props.setCustomer}
            options={customers}
            loading={loading}
            hint={props.showHint && "Note: This will create a new customer"}
          />
        </Grid>
        {
          !props.hideSelect && (
            <Grid item xs={1} className={classes.buttonAlign}>
              <RefreshButton onClick={getLocation} disabled={loading}/>
            </Grid>
          )
        }
      </Grid>
      <InfoDialog open={alertOpen} onClose={handleClose} leftButton="Cancel" rightButton="Enter Manually" title="No customers found">
        <DialogContentText>
          No BakeMark customers were found near your location. Please enter the customer manually.
        </DialogContentText>
      </InfoDialog>
    </>
  );
}

export default CustomerInput
