import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  var spaces = s.replace(/([A-Z])/g, " $1");
  return spaces.charAt(0).toUpperCase() + spaces.slice(1)
}

function FlourDetailsCheckboxInputs(props) {
  const fields = ["bleached", "enriched", "malted", "ascorbicAcid", "enzymes", "bromated"]
  
  return (
    <Grid container>
      {fields.map(field => (
        <Grid item xs={6}>
          <FormControlLabel
            control={<Checkbox checked={props.data[field]} onChange={props.onChange && props.onChange(field)}/>}
            label={capitalize(field)}
            disabled={props.disabled}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default FlourDetailsCheckboxInputs;
