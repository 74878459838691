import React, { useState, useEffect } from 'react';
import DataTable from '../DataTable/DataTable';
import axios from 'axios';
import qs from 'querystring'
import MoreInfoDialog from '../MoreInfoDialog/MoreInfoDialog';
import ManageProducts from '../ManageProducts/ManageProducts';
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';
import { withRouter } from 'react-router-dom'

const equivalentProductColumns = [
  {
    width: 180,
    label: 'Category',
    dataKey: 'category_name',
  },
  {
    width: 180,
    label: 'Subcategory',
    dataKey: 'subcategory_name',
  },
  {
    width: 140,
    label: 'BakeMark Brand',
    dataKey: 'bakemark_brand_name',
  },
  {
    width: 300,
    flexGrow: 1.0,
    label: 'BakeMark Product Name',
    dataKey: 'bakemark_product_name',
    cellContentRenderer: ({ cellData }) => (
      cellData ? cellData : <span style={{ fontStyle: 'italic' }}>No equivalent BakeMark product</span>
    )
  },
  {
    width: 155,
    label: 'BakeMark Product Code',
    dataKey: 'bakemark_product_code',
    numeric: true,
  },
  {
    width: 150,
    label: 'Competitive Brand',
    dataKey: 'competitive_brand_name',
  },
  {
    width: 300,
    flexGrow: 1.0,
    label: 'Competitive Product Name',
    dataKey: 'product_name',
  },
  {
    width: 155,
    label: 'Competitive Product Code',
    dataKey: 'product_code',
    numeric: true,
  },
  {
    width: 100,
    label: 'Actions',
    dataKey: 'master_product_id',
    cellContentRenderer: ({ rowData }) => (
      <MoreInfoDialog data={rowData} />
    )
  },
]

function EquivalentProducts(props) {
  const [loading, setLoading] = useState(false)
  const [fullyLoaded, setFullyLoaded] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [equivalentProducts, setEquivalentProducts] = useState([])
  const [sortData, setSortData] = useState({
    sortBy: "master_product_id",
    sortDirection: "ASC"
  })

  const getEquivalentProducts = async (startIndex, count) => {
    if (loading || fullyLoaded) {
      return
    }

    setLoading(true)

    let results
    try {
      results = await axios.get('/v1/equivalent-products?' + qs.stringify({
        startIndex,
        count,
        ...sortData,
      }))
    } catch (err) {
      console.warn("Could not get equivalent products", err)
      setLoading(false)
      return
    }

    const normalizedResults = results.data['array'].map((result) => {
      return result
    })

    if (startIndex === 0) {
      setEquivalentProducts(normalizedResults)
    } else {
      setEquivalentProducts(equivalentProducts.concat(normalizedResults))
    }
    setLoading(false)

    if (normalizedResults.length !== count) {
      setFullyLoaded(true)
    }
  }

  const getRow = ({ index }) => {
    const data = equivalentProducts
    if (index < data.length - 1) {
      return data[index]
    } else if (index >= data.length - 1) {
      getEquivalentProducts(equivalentProducts.length, 25)
      return data[index]
    }
  }

  const handleSort = (sortBy, sortDirection) => {
    setFullyLoaded(false)
    setSortData({ sortBy, sortDirection })
    setEquivalentProducts([])
  }

  useEffect(() => {
    getEquivalentProducts(0, 25)
  }, [sortData.sortBy, sortData.sortDirection])

  return (
    <>
      <DataTable
        rowCount={equivalentProducts.length}
        rowGetter={getRow}
        loading={loading}
        sortData={sortData}
        onSort={handleSort} 
        columns={equivalentProductColumns} />
      <ManageProducts onSuccess={() => setSnackbar(true)}/>
      <ColoredSnackbar open={snackbar} onClose={() => setSnackbar(false)} event="MANAGE_SUCCESS" />
    </>
  );
}

export default withRouter(EquivalentProducts)
