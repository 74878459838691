import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

function PrivateRoute(props) {
  const { auth, managersOnly, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        !auth.isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location, event: "LOGIN_REQUIRED" }
            }}
          />
        ) : !auth.isBakeMarkAccount ? (
          <Redirect to="/invalid-account" />
        ) : managersOnly && !auth.isManager ? (
          <Redirect
            to={{
              pathname: "/inquiry",
              state: { event: "NO_MANAGER_PERMISSION" }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default connect(mapStateToProps, null, null, {pure: false})(PrivateRoute);
