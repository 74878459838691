import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { CLEAR_MATCH_INQUIRY_FORM, SET_NEW_EQUIVALENT_PRODUCTS } from '../../store/actionTypes';
import { Fab, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon, Iso as IsoIcon } from '@material-ui/icons';
import InfoDialog from '../InfoDialog/InfoDialog';
import NewProductInputForm from './NewProductInputForm';
import NewEquivalentProductsInputForm from './NewEquivalentProductsInputForm';
import DeleteBakemarkProductForm from './DeleteBakemarkProductForm';
import DeleteCompetitiveProductForm from './DeleteCompetitveProductForm';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(0.75),
  },
}))

const mapStateToProps = (state) => {
  return {
    equivalentProducts: state.createNewProductData.equivalentProducts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNewEquivalentProducts: (equivalentProducts) => {
      dispatch({
        type: SET_NEW_EQUIVALENT_PRODUCTS,
        equivalentProducts,
      })
    },
    clearNewData: () => {
      dispatch({
        type: CLEAR_MATCH_INQUIRY_FORM
      })
    },
  }
}

function ManageProducts(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  
  function handleClickOpen() {
    setOpen(true);
  }
  
  const handleClose = (submitSuccess) => {
    setOpen(false);
    props.clearNewData()
    if (submitSuccess === true) {
      props.onSuccess()
    }
    setTimeout(() => setSelectedValue(null), 500)
  };
  
  const manageOptions = [
    {
      key: "bakemarkProduct",
      icon: <AddIcon />,
      text: "Add BakeMark Product",
      form: <NewProductInputForm field="BakeMark Product" onSubmit={handleClose} isBakemark/>,
    },
    {
      key: "competitiveProduct",
      icon: <AddIcon />,
      text: "Add Competitive Product",
      form: <NewProductInputForm field="Competitive Product" onSubmit={handleClose}/>,
    },
    {
      key: "productRelationship",
      icon: <AddIcon />,
      text: "Add Product Relationship",
      form: <NewEquivalentProductsInputForm onChange={props.setNewEquivalentProducts} equivalentProducts={props.equivalentProducts} onSubmit={handleClose}/>,
    },
    {
      key: "deleteBakemarkProduct",
      icon: <DeleteIcon />,
      text: "Delete BakeMark Products",
      form: <DeleteBakemarkProductForm onSubmit={handleClose}/>,
    },
    {
      key: "deleteCompetitiveProduct",
      icon: <DeleteIcon />,
      text: "Delete Competitive Product",
      form: <DeleteCompetitiveProductForm onSubmit={handleClose}/>,
    },
  ]

  return (
    <>
      <Fab variant="extended" color="secondary" aria-label="Manage Products" className={classes.fab} onClick={handleClickOpen}>
        <IsoIcon className={classes.extendedIcon} />
        Manage Products
      </Fab>
      <InfoDialog
        open={open}
        title={selectedValue ? manageOptions.find(x => x.key === selectedValue).text : "Manage Products"}
        rightButton="Cancel"
        onClose={handleClose}
      >
        {selectedValue ? (
          manageOptions.find(x => x.key === selectedValue).form
        ) : (
          <List>
            {manageOptions.map(option => (
              <ListItem button onClick={() => setSelectedValue(option.key)} key={option.key}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    {option.icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={option.text} />
              </ListItem>
            ))}
          </List>
        )}
      </InfoDialog>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageProducts);
