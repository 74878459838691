import React, { useState } from 'react'
import SubmitButton from '../SubmitButton/SubmitButton';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import CompetitiveProductInput from './CompetitiveProductInput';

const useStyles = makeStyles(theme => ({
  autocompletePadding: {
    paddingBottom: theme.spacing(13),
  },
  submitButton: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  }
}));

const DeleteCompetitiveProductForm = (props) => {
  const classes = useStyles()
  const [productToDelete, setProductToDelete] = useState(null)
  const [replacementProduct, setReplacementProduct] = useState(null)
  const [checkbox, setCheckbox] = useState(false)
  console.log(replacementProduct)

  const handleSubmit = async () => {
    try {
      await axios.post('/v1/competitive-product/delete', {
        productToDelete: productToDelete.value,
        replacementProduct: replacementProduct.value,
      })
    } catch (err) {
      console.warn("Could not delete competitive product", err)
    }

    props.onSubmit(true)
  }

  const isFormInvalid = () => {
    if (!productToDelete || !replacementProduct || !checkbox) {
      return true
    }
    return false
  }

  const handleCheckbox = (event) => {
    setCheckbox(event.target.checked)
  }

  const handleDeletedChange = (array) => {
    setProductToDelete(array[0])
  }

  const handleReplacementChange = (array) => {
    setReplacementProduct(array[0])
  }

  return (
    <div className={classes.autocompletePadding}>
      <CompetitiveProductInput
        competitiveProducts={productToDelete ? [productToDelete] : []}
        setCompetitiveProducts={handleDeletedChange}
        disabled={() => Boolean(productToDelete)}
        label="Product to delete"
      />
      <CompetitiveProductInput
        competitiveProducts={replacementProduct ? [replacementProduct] : []}
        setCompetitiveProducts={handleReplacementChange}
        disabled={() => Boolean(replacementProduct)}
        label="Product to substitute for it"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkbox}
            onChange={handleCheckbox}
          />
        }
        label="I understand deleting products is permanent and CANNOT be undone"
      />
      <SubmitButton noRedirect onClick={handleSubmit} className={classes.submitButton} disabled={isFormInvalid}>Delete</SubmitButton>
    </div>
  )
}

export default DeleteCompetitiveProductForm
