import React, { useState } from 'react';
import InfoDialog from '../InfoDialog/InfoDialog';
import { IconButton, DialogContentText } from '@material-ui/core';
import { ViewList as ViewListIcon } from '@material-ui/icons';
import InquiryCard from '../InquiryCard/InquiryCard';
import axios from 'axios'
import qs from 'querystring'

function ViewResultsDialog(props) {
  const [open, setOpen] = useState(false)
  const [inquiryResults, setInquiryResults] = useState([])
  
  const handleOpen = async () => {
    if (props.data.competitive_product_id) {
      var results = await axios.get('/v1/bakemark-products?' + qs.stringify({
        competitiveProductId: props.data.competitive_product_id
      }))
      setInquiryResults(results.data['array'])
    }
    setOpen(true);
  }
  
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <>
      <IconButton aria-label="Info" onClick={handleOpen} title="View inquiry results">
        <ViewListIcon />
      </IconButton>
      <InfoDialog open={open} onClose={handleClose} title="Inquiry Results" rightButton="Ok">
        {
          inquiryResults.length > 0 ? (
            inquiryResults.map((inquiry, index) => (
              <InquiryCard inquiry={inquiry} key={index} />
            ))
          ) : (
            <DialogContentText>
              No matching BakeMark products
            </DialogContentText>
          )
        }
      </InfoDialog>
    </>
  );
}

export default ViewResultsDialog
