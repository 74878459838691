import React, { useState } from 'react';
import InfoDialog from '../InfoDialog/InfoDialog';
import { IconButton } from '@material-ui/core';
import { Image as ImageIcon } from '@material-ui/icons';
import InquiryPictures from './InquiryPictures';

function PicturesDialog(props) {
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }

  return (
    <>
      <IconButton aria-label="Info" onClick={handleOpen} style={props.hide ? {} : { visibility: 'hidden' }}>
        <ImageIcon />
      </IconButton>
      <InfoDialog open={open} onClose={handleClose} title="Pictures" rightButton="Ok">
        <InquiryPictures submittedInquiryId={props.submittedInquiryId}/>
      </InfoDialog>
    </>
  );
}

export default PicturesDialog
