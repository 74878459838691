import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import OneInputSelect from './OneInputSelect';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  }
}));

function CategoryInput(props) {
  const classes = useStyles();
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  
  async function getCategories() {
    setLoading(true)
    
    let results
    try {
      results = await axios.get('/v1/category')
    } catch (err) {
      console.warn("Could not get categories", err)
      setLoading(false)
      return
    }

    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['category_name'],
        value: result['category_id']
      }
    })

    setCategories(normalizedResults)
    setLoading(false)
  }
  
  useEffect(() => {
    getCategories();
  }, [])
  
  return (
    <Grid container>
      <Grid item xs={11}>
        <OneInputSelect
          selectValue={props.category}
          textValue={props.categoryOther}
          field="category"
          handleChange={props.setCategory}
          options={categories}
          loading={loading}
          hint={props.showHint && "Note: This will create a new category"}
        />
      </Grid>
      <Grid item xs={1} className={classes.buttonAlign}>
        <RefreshButton onClick={getCategories} disabled={loading}/>
      </Grid>
    </Grid>
  );
}

export default CategoryInput;
