import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import iosLocationPrompt from '../../assets/ios-location-prompt.png';
import androidLocationPrompt from '../../assets/android-location-prompt.png';
import desktopLocationPrompt from '../../assets/desktop-location-prompt.png';
import { Redirect } from 'react-router-dom';
import { useCurrentPosition } from 'react-use-geolocation';
import { red } from '@material-ui/core/colors';
import TextAppBar from '../TextAppBar/TextAppBar';
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: 'center'
  },
  locationErrorText: {
    textAlign: 'center',
    color: red
  },
  promptImg: {
    borderRadius: '20px',
    width: '100%'
  }
}));

const getLocationPrompt = () => {
  const desktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const android = /android/i.test(navigator.userAgent)
  // const iOS = /iPad|iPhone|iPod/i.test(navigator.userAgent) && !window.MSStream
  if(desktop) {
    return desktopLocationPrompt
  } else if(android) {
    return androidLocationPrompt
  } else {
    return iosLocationPrompt
  }
}

const getLinkForBrowser = () => {
  const desktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const firefox = typeof InstallTrigger !== 'undefined';
  // eslint-disable-next-line
  const safari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)) || (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent));
  const IE = /*@cc_on!@*/false || !!document.documentMode;
  const edge = !IE && !!window.StyleMedia;
  const chrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  
  if(chrome) {
    return "https://support.google.com/chrome/answer/114662"
  } else if(desktop) {
    if(firefox) {
      return "https://support.mozilla.org/en-US/kb/control-center-site-privacy-and-security-firefox"
    } else if(edge) {
      return "https://microsoftedgetips.microsoft.com/en-us/3/45"
    } else if(safari) {
      return "https://support.apple.com/guide/safari/customize-settings-per-website-ibrw7f78f7fe/mac"
    }
  } else {
    if(safari) {
      return "https://support.apple.com/en-us/HT201265"
    }
  }
  
  return null
}

function EnableLocation(props) {
  const classes = useStyles();
  const [position, error] = useCurrentPosition();
  const [open, setOpen] = useState(false);
  const linkForBrowser = getLinkForBrowser()

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error])

  if (position) return <Redirect to={{ pathname: "/", state: { event: "LOCATION_GRANTED" } }} />;

  return (
    <Grid item xs={12} sm={11} md={10} lg={9} xl={6}>
      <TextAppBar>Enable Location</TextAppBar>
      <Grid container className={classes.centerAlign} justify="center">
        {
          error ? (
            <Grid item xs={12}>
              <h2 className={classes.locationErrorText}>Error: Location Denied</h2>
            </Grid>
          ): (
            <Grid item xs={12}>
              <h2 className={classes.centerAlign}>Location Access Required</h2>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <p>Location is needed to accurately identify nearby customers</p>
        </Grid>
        <Grid item xs={9} md={6} lg={4}>
          <img src={getLocationPrompt()} alt="Location Prompt Example" className={classes.promptImg}/>
        </Grid>
        <Grid item xs={12}>
          {
            error && (
              <>
                <p>Please clear browser site settings</p>
                {
                  linkForBrowser && (
                    <Button variant="contained" color="primary" href={linkForBrowser} target="_blank" rel="noopener" className={classes.button}>
                      Instructions
                    </Button>
                  )
                }
              </>
            )
          }
        </Grid>
      </Grid>
      <ColoredSnackbar open={open} onClose={() => setOpen(false)} event="LOCATION_DENIED" />
    </Grid>
  );
}

export default EnableLocation;
