import React, { useState } from 'react';
import InfoDialog from '../InfoDialog/InfoDialog';
import { Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { REMOVE_POTENTIAL_PRICE_INQUIRY } from '../../store/actionTypes';
import ResolvePriceInquiryForm from '../ResolveForms/ResolvePriceInquiryForm';
import InquiryPictures from '../PicturesDialog/InquiryPictures';

const mapDispatchToProps = (dispatch) => {
  return {
    removePriceInquiry: (index) => {
      dispatch({
        type: REMOVE_POTENTIAL_PRICE_INQUIRY,
        index
      })
    },
  }
}

function ResolvePriceRowDialog(props) {
  const [open, setOpen] = useState(false)

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }
  
  const handleCloseAndRemove = () => {
    setOpen(false)
    props.removePriceInquiry(props.rowIndex)
  }

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>Review</Button>
      <InfoDialog open={open} onClose={handleClose} title="Review row" rightButton="Cancel" size="md">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InquiryPictures submittedInquiryId={props.data.submitted_inquiry_id}/>
          </Grid>
          <Grid item xs={6}>
            <ResolvePriceInquiryForm data={props.data} rowIndex={props.rowIndex} onClose={handleCloseAndRemove} />
          </Grid>
        </Grid>
      </InfoDialog>
    </>
  );
}

export default connect(null, mapDispatchToProps)(ResolvePriceRowDialog)
