import React from 'react';
import { Grid, TextField } from '@material-ui/core';

const unitOfMeasureOptions = ["", "lbs", "gal", "oz", "count", "kg", "g", "L"]

function NetWeightInput(props) {
  
  const handleChange = fieldName => (event) => {
    const fieldValue = event.target.value

    if (fieldName === 'netWeight') {
      props.setNetWeight(fieldValue)
    } else {
      props.setUnitOfMeasure(fieldValue)
    }
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          required={props.required}
          fullWidth
          label="Net Weight"
          type="number"
          value={props.netWeight}
          onChange={handleChange('netWeight')}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={props.fullWidth ? 5 : 4}>
        <TextField
          select
          required={props.required}
          fullWidth
          label="Unit"
          value={props.unitOfMeasure}
          onChange={handleChange('unitOfMeasure')}
          SelectProps={{
            native: true,
          }}
          margin="normal"
          variant="outlined"
        >
          {
            unitOfMeasureOptions.map((option) => <option value={option} key={option}>{option}</option>)
          }
        </TextField>
      </Grid>
    </Grid>
  );
}

export default NetWeightInput;
