import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Grid, MenuItem, Badge, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LOGOUT } from '../../store/actionTypes';
import UserAppBarMenu from '../UserAppBarMenu/UserAppBarMenu'
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  menuItemBadge: {
    marginRight: 8
  },
  menuItemText: {
    paddingRight: 12
  },
  menuSpacing: {
    marginRight: theme.spacing(2),
  },
  arrowIcon: {
    marginLeft: '4px',
    marginRight: '8px'
  },
}))

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({
        type: LOGOUT
      })
    },
  }
}

function UserAppBar(props) {
  const classes = useStyles();

  function getNumPendingInquiries() {
    if (localStorage.pendingInquiries) {
      return JSON.parse(localStorage.pendingInquiries).length
    } else {
      return 0;
    }
  }

  return (
    <AppBar position="fixed" color="primary">
      <Grid container justify="center">
        <Grid item xs={12} sm={11} md={10} lg={9} xl={6}>
          <Toolbar disableGutters={props.showBackArrow}>
            {
              props.showBackArrow && (
                <IconButton color="inherit" className={classes.arrowIcon} onClick={() => props.history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
              )
            }
            <UserAppBarMenu auth={props.auth} onLogout={props.logout} className={props.showBackArrow && classes.menuSpacing}>
              <MenuItem onClick={() => props.history.push("/inquiry/history")}>
                <Badge color="primary" badgeContent={getNumPendingInquiries()} className={classes.menuItemBadge}>
                  <span className={classes.menuItemText}>Inquiry History</span>
                </Badge>
              </MenuItem>
            </UserAppBarMenu>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAppBar));
