import { combineReducers } from "redux";
import auth from './auth'
import userLocation from './userLocation'
import matchInquiryForm from './matchInquiryForm'
import potentialAdditions from './potentialAdditions'
import createNewProductData from './createNewProductData'

const reducer = combineReducers({
  auth,
  userLocation,
  matchInquiryForm,
  potentialAdditions,
  createNewProductData,
})

export default reducer;
