import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import SubmitButton from '../SubmitButton/SubmitButton';
import TextAppBar from '../TextAppBar/TextAppBar';
import InquiryCard from '../InquiryCard/InquiryCard';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  topMargin: {
    marginTop: theme.spacing()
  },
  topHalfMargin: {
    marginTop: theme.spacing(0.5)
  }
}));

const noResultsInquiry = {
  product: {
    label: "No matching BakeMark products",
    code: "",
    manufacturedInCanada: false,
  },
  competitor: {
    label: "",
    code: "",
  }
}

function InquiryResults(props) {
  const classes = useStyles();
  const results = (props.location.state && props.location.state.results) || []
  const isCanada = useSelector(state => state.auth.isCanada)

  const sortByCanada = (a, b) => {
    if (a.product.manufacturedInCanada && b.product.manufacturedInCanada) {
      return 0
    } else if (a.product.manufacturedInCanada) {
      return -1
    } else {
      return 1
    }
  }

  let inquiryResults
  if (isCanada) {
    inquiryResults = [...results].sort(sortByCanada)
  } else {
    inquiryResults = results.filter(({ product }) => {
      return !product.manufacturedInCanada
    })
  }

  return (
    <>
      <TextAppBar>Inquiry Results</TextAppBar>
      <Grid container className={classes.topMargin}>
        <Grid item xs={12}>
          {inquiryResults.map((inquiry) => (
            <InquiryCard inquiry={inquiry} key={`${inquiry.product.label}${inquiry.product.code}`} />
          ))}
          {inquiryResults.length === 0 && (
            <InquiryCard inquiry={noResultsInquiry} />
          )}
        </Grid>
        <Grid item xs={12}>
          <p>Always check current status of what's stocked in your location.</p>
        </Grid>
        <Grid item xs={12} className={classes.topHalfMargin}>
          <SubmitButton path="/">Done</SubmitButton>
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(InquiryResults);
