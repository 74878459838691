import React from 'react';
import { Fade, LinearProgress } from '@material-ui/core';

function LoadingBar(props) {
  
  return (
    <Fade
      in={props.loading}
      style={{
        transitionDelay: props.loading ? '800ms' : '0ms',
      }}
      unmountOnExit
    >
      <LinearProgress />
    </Fade>
  )
}

export default LoadingBar;
