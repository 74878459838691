import {
  SET_MATCH_INQUIRY_ID,
  SET_MATCH_INQUIRY_CATEGORY,
  SET_MATCH_INQUIRY_SUBCATEGORY,
  SET_MATCH_INQUIRY_FLOUR_DETAILS,
  SET_MATCH_INQUIRY_COMPETITOR,
  SET_MATCH_INQUIRY_PRODUCT,
  SET_MATCH_INQUIRY_CUSTOMER,
  SET_MATCH_INQUIRY_NET_WEIGHT,
  SET_MATCH_INQUIRY_UNIT_OF_MEASURE,
  SET_MATCH_INQUIRY_BAKEMARK_PRODUCTS,
  SET_MATCH_INQUIRY_REVIEW_NOTES,
  SET_MATCH_INQUIRY_PICTURES,
  SET_MATCH_INQUIRY_PRICE,
  SET_MATCH_INQUIRY_MANUFACTURED_IN_CANADA,
  SET_MATCH_INQUIRY_FORM,
  CLEAR_MATCH_INQUIRY_FORM,
} from '../actionTypes';

// -1 = Default empty state, -2 = 'Other...'
const matchInquiryFormDefaultState = {
  inquiryId: null,
  category: { label: "", value: -1 },
  subcategory: { label: "", value: -1 },
  flourDetailsId: null,
  flourDetails: { 
    protein: "",
    ash: "",
    bleached: false,
    enriched: false,
    malted: false,
    ascorbicAcid: false,
    enzymes: false,
    bromated: false,
  },
  competitor: { label: "", value: -1 },
  product: { label: "", value: { name: "", code: ""} },
  customer: { label: "", value: { name: "", code: "" } },
  categoryOther: "",
  subcategoryOther: "",
  competitorOther: "",
  productOther: { name: "", code: "" },
  customerOther: { name: "", code: "" },
  netWeight: "",
  unitOfMeasure: "",
  bakemarkProducts: [],
  reviewNotes: "",
  pictures: [],
  price: "",
  date: undefined,
  userId: undefined,
  manufacturedInCanada: false,
}

const matchInquiryFormReducer = (state = matchInquiryFormDefaultState, action) => {
  switch (action.type) {
    case SET_MATCH_INQUIRY_ID:
      return {
        ...state,
        inquiryId: action.inquiryId
      }
    case SET_MATCH_INQUIRY_CATEGORY:
      return action.isOther ? {
        ...state,
        categoryOther: action.category,
        flourDetails: matchInquiryFormDefaultState.flourDetails,
      } : {
        ...state,
        category: action.category,
        subcategory: matchInquiryFormDefaultState.subcategory,
        product: matchInquiryFormDefaultState.product,
        flourDetails: matchInquiryFormDefaultState.flourDetails,
      }
    case SET_MATCH_INQUIRY_SUBCATEGORY:
      return action.isOther ? {
        ...state,
        subcategoryOther: action.subcategory
      } : {
        ...state,
        subcategory: action.subcategory,
        product: matchInquiryFormDefaultState.product,
      }
    case SET_MATCH_INQUIRY_FLOUR_DETAILS:
      return {
        ...state,
        flourDetails: action.flourDetails
      }
    case SET_MATCH_INQUIRY_COMPETITOR:
      return action.isOther ? {
        ...state,
        competitorOther: action.competitor
      } : {
        ...state,
        competitor: action.competitor,
        product: matchInquiryFormDefaultState.product
      }
    case SET_MATCH_INQUIRY_PRODUCT:
      return action.isOther ? {
        ...state,
        productOther: action.product
      } : {
        ...state,
        product: action.product
      }
    case SET_MATCH_INQUIRY_CUSTOMER:
      return action.isOther ? {
        ...state,
        customerOther: action.customer
      } : {
        ...state,
        customer: action.customer
      }
    case SET_MATCH_INQUIRY_NET_WEIGHT:
      return {
        ...state,
        netWeight: action.netWeight
      }
    case SET_MATCH_INQUIRY_UNIT_OF_MEASURE:
      return {
        ...state,
        unitOfMeasure: action.unitOfMeasure
      }
    case SET_MATCH_INQUIRY_BAKEMARK_PRODUCTS:
      return {
        ...state,
        bakemarkProducts: action.bakemarkProducts
      }
    case SET_MATCH_INQUIRY_REVIEW_NOTES:
      return {
        ...state,
        reviewNotes: action.reviewNotes
      }
    case SET_MATCH_INQUIRY_PICTURES:
      return {
        ...state,
        pictures: action.pictures
      }
    case SET_MATCH_INQUIRY_PRICE:
      return {
        ...state,
        price: action.price
      }
    case SET_MATCH_INQUIRY_MANUFACTURED_IN_CANADA:
      return {
        ...state,
        manufacturedInCanada: action.manufacturedInCanada
      }
    case SET_MATCH_INQUIRY_FORM:
      return {
        ...matchInquiryFormDefaultState,
        ...action.inquiryForm
      }
    case CLEAR_MATCH_INQUIRY_FORM:
      return matchInquiryFormDefaultState
    default:
      return state;
  }
}

export default matchInquiryFormReducer
