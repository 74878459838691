import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Fab } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import InfoDialog from '../InfoDialog/InfoDialog';
import { DatePicker } from '@material-ui/pickers';
import clsx from 'clsx'
import moment from 'moment'
import axios from 'axios'
import qs from 'querystring'
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(0.75),
  },
  datePicker: {
    marginTop: theme.spacing(0.5),
  },
  rightMargin: {
    marginRight: theme.spacing(2),
  },
}))

const saveData = (function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, fileName) {
    const blob = new Blob([data], { type: "octet/stream" })
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
}());

function ExportInquries(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const initStartDate = moment().subtract(7, 'd').startOf('day')
  const initEndDate = moment().endOf('day')
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getSubmittedInquiries = async (startDate, endDate) => {
    if(loading) return
    setLoading(true)

    let results
    try {
      results = await axios.get('/v1/inquiry/date?' + qs.stringify({
        inquiryType: props.inquiryType,
        startDate,
        endDate
      }))
    } catch (err) {
      console.warn("Could not get inquiries to export", err)
      setLoading(false)
      return []
    }

    const normalizedResults = props.mapExportData(results.data['array'])

    setLoading(false)
    return normalizedResults
  }

  const handleExport = async () => {
    setOpen(false)
    const momentStartDate = moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss")
    const momentendDate = moment.utc(endDate).format("YYYY-MM-DD HH:mm:ss")

    const inquiryData = await getSubmittedInquiries(momentStartDate, momentendDate)

    if (inquiryData.length) {
      const items = inquiryData
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      let maxIndex = 0
      let maxVal = Object.keys(items[0]).length
      items.forEach((item, index) => {
        if (Object.keys(item).length > maxVal) {
          maxIndex = index
          maxVal = Object.keys(item).length
        }
      })
      const header = Object.keys(items[maxIndex])
      let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
      csv.unshift(header.join(','))
      csv = csv.join('\r\n')
  
      saveData(csv, `export_${moment().format("MM-DD-YYYY_HH:mm:ss")}.csv`)
    } else {
      setSnackbar(true)
    }
  }

  return (
    <>
      <Fab variant="extended" color="secondary" aria-label="Create" className={classes.fab} onClick={handleClickOpen}>
        <SaveIcon className={classes.extendedIcon} />
        Export Data
      </Fab>
      <InfoDialog
        open={open}
        title="Export Inquiries"
        leftButton="Cancel"
        rightButton="Export"
        onClose={handleClose}
        onRightButton={handleExport}
        loading={loading}
      >
        <DatePicker
          disableFuture
          autoOk
          inputVariant="outlined"
          label="Start"
          value={startDate}
          onChange={setStartDate}
          format="M/D/YY"
          className={clsx(classes.datePicker, classes.rightMargin)}
        />
        <DatePicker
          disableFuture
          autoOk
          showTodayButton
          inputVariant="outlined"
          label="End"
          value={endDate}
          onChange={setEndDate}
          format="M/D/YY"
          className={classes.datePicker}
        />
      </InfoDialog>
      <ColoredSnackbar open={snackbar} onClose={() => setSnackbar(false)} event="NO_DATA_TO_EXPORT" />
    </>
  );
}

export default ExportInquries;
