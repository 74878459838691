import {
  CLEAR_CREATE_NEW_DATA,
  SET_NEW_EQUIVALENT_PRODUCTS,
} from '../actionTypes';

const createNewEquivalentProductsDefaultState = {
  bakemarkProducts: [],
  competitiveProducts: [],
}

const createNewProductDataDefaultState = {
  equivalentProducts: createNewEquivalentProductsDefaultState,
}

const createNewProductDataReducer = (state = createNewProductDataDefaultState, action) => {
  switch (action.type) {
    case SET_NEW_EQUIVALENT_PRODUCTS:
      return {
        ...state,
        equivalentProducts: {
          ...state.equivalentProducts,
          ...action.equivalentProducts,
        }
      }
    case CLEAR_CREATE_NEW_DATA:
      return createNewProductDataDefaultState
    default:
      return state;
  }
}

export default createNewProductDataReducer
