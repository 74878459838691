import {
  SET_LOCATION,
} from '../actionTypes';

const userLocationDefaultState = {
  latitude: undefined,
  longitude: undefined,
}

const userLocationReducer = (state = userLocationDefaultState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
      }
    default:
      return state;
  }
}

export default userLocationReducer
