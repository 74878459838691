import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core';
import { HowToReg, SaveAlt, Menu as MenuIcon, CompareArrows, CameraAlt } from '@material-ui/icons';
import { LOGOUT } from '../../store/actionTypes';
import UserAppBarMenu from '../UserAppBarMenu/UserAppBarMenu'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'

const drawerItems = [
  {
    text: "Review Dashboard",
    icon: <HowToReg/>,
    path: "/manage/additions",
  },
  {
    text: "Competitive Match Inquiries",
    icon: <SaveAlt/>,
    path: "/manage/match-inquiries",
  },
  {
    text: "Price Inquiries",
    icon: <CameraAlt/>,
    path: "/manage/price-inquiries",
  },
  {
    text: "Equivalent Products",
    icon: <CompareArrows/>,
    path: "/manage/equivalent-products",
  },
]

const useStyles = makeStyles(theme => ({
  list: {
    width: 320,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  headerText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight: theme.spacing(1),
  },
  drawerLink: {
    textDecoration: 'none',
    color: 'initial',
  },
}));

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({
        type: LOGOUT
      })
    },
  }
}

function DrawerAppBar(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const getTitleByPath = () => {
    const drawerItem = drawerItems.find(x => x.path === props.location.pathname)
    return (drawerItem && drawerItem.text) || null
  }

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={() => setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.headerText}>
            {getTitleByPath()}
          </Typography>
          <UserAppBarMenu auth={props.auth} onLogout={props.logout}/>          
        </Toolbar>
      </AppBar>
      <Drawer open={drawerOpen} onClose={toggleDrawer}>
        <div
          tabIndex={0}
          role="button"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <div className={classes.list}>
            <List>
              {drawerItems.map((item) => (
                <Link to={item.path} key={item.text} className={classes.drawerLink}>
                  <ListItem button>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DrawerAppBar))
