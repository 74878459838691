import React from 'react';
import BakemarkProductInput from './BakemarkProductInput';
import { makeStyles } from '@material-ui/styles';
import CompetitiveProductInput from './CompetitiveProductInput';
import SubmitButton from '../SubmitButton/SubmitButton';
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  autocompletePadding: {
    paddingBottom: theme.spacing(13),
  },
  submitButton: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
  }
}));

function NewEquivalentProductsInputForm(props) {
  const classes = useStyles()

  const isFormInvalid = () => {
    const { bakemarkProducts, competitiveProducts } = props.equivalentProducts

    if (bakemarkProducts.length < 1 || competitiveProducts.length < 1) {
      return true
    }
    return false
  }

  const competitiveProductsDisabled = () => {
    if (props.equivalentProducts.competitiveProducts.length > 0) {
      return true
    }
    return false
  }
  
  const handleSubmit = async () => {
    const equivalentProductsData = {
      ...props.equivalentProducts,
      bakemarkProducts: props.equivalentProducts.bakemarkProducts.map(product => product.value),
      competitiveProduct: props.equivalentProducts.competitiveProducts[0].value,
    }

    try {
      await axios.post('/v1/equivalent-products', {
        ...equivalentProductsData,
      })
    } catch (err) {
      console.warn("Could not create new equivalent product relationship", err)
    }

    props.onSubmit(true)
  }

  const handleSelectChange = textFieldName => (fieldValue, isOther) => {
    let newObj = !isOther ? {
      [textFieldName]: fieldValue
    } : {
      [textFieldName + "Other"]: fieldValue
    }

    props.onChange(newObj);
  }

  return (
    <div className={classes.autocompletePadding}>
      <BakemarkProductInput
        bakemarkProducts={props.equivalentProducts.bakemarkProducts}
        setBakemarkProducts={handleSelectChange("bakemarkProducts")}
      />
      <CompetitiveProductInput
        competitiveProducts={props.equivalentProducts.competitiveProducts}
        setCompetitiveProducts={handleSelectChange("competitiveProducts")}
        disabled={competitiveProductsDisabled}
      />
      <SubmitButton noRedirect onClick={handleSubmit} className={classes.submitButton} disabled={isFormInvalid}>Create</SubmitButton>
    </div>
  )
}

export default NewEquivalentProductsInputForm;
