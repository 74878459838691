import React, { useState } from 'react';
import InfoDialog from '../InfoDialog/InfoDialog';
import { IconButton, DialogContentText } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

function MoreInfoDialog(props) {
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }

  return (
    <>
      <IconButton aria-label="Info" onClick={handleOpen}>
        <InfoIcon />
      </IconButton>
      <InfoDialog open={open} onClose={handleClose} title="Raw Row Data" rightButton="Ok">
        <DialogContentText>
          {
            Object.keys(props.data).map((key) => (
              <span key={key}>{key + ": " + JSON.stringify(props.data[key])}<br/></span>
            ))
          }
        </DialogContentText>
      </InfoDialog>
    </>
  );
}

export default MoreInfoDialog
