import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './components/AppRouter/AppRouter';
import store from './store/store';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { red, blue, purple } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

axios.defaults.baseURL = process.env.NODE_ENV === "development" ? 'http://localhost:80/api' : 'https://mybakemarkapp.com/api'

const theme = {
  palette: {
    primary: blue,
    secondary: purple,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  spacing: 16,
};

const useStyles = makeStyles(() => ({
  defaults: {
    height: "100%",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <ThemeProvider theme={createMuiTheme(theme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className={classes.defaults}>
            <Router>
              <AppRouter />
            </Router>
          </div>
          <CssBaseline />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
