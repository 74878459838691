import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import TextAppBar from '../TextAppBar/TextAppBar';
import ColoredSnackbar from '../ColoredSnackbar/ColoredSnackbar';
import { LOGOUT } from '../../store/actionTypes';

const useStyles = makeStyles(theme => ({
  centerAlign: {
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({
        type: LOGOUT
      })
    },
  }
}

function InvalidAccount(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [])

  const handleLogout = () => {
    localStorage.removeItem("pendingInquiries");
    props.auth.userAgentApplication.logout();
    props.logout();
  }

  return (
    <Grid item xs={12} sm={11} md={10} lg={9} xl={6}>
      <TextAppBar>Invalid Account</TextAppBar>
      <Grid container className={classes.centerAlign} justify="center">
        <Grid item xs={12}>
          <p>You must use a BakeMark Office 365 account in order to log in</p>
        </Grid>
        <Grid item xs={12}>
          <p>Please logout and log back in using your BakeMark Office 365 account</p>
          <Button onClick={handleLogout} variant="contained" color="primary" className={classes.button}>
            Logout
          </Button>
        </Grid>
      </Grid>
      <ColoredSnackbar open={open} onClose={() => setOpen(false)} event="UNAUTHORIZED_USER" />
    </Grid>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InvalidAccount)
