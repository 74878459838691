import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { Grid, Chip } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';
import AutosuggestAutocomplete from './AutosuggestAutocomplete';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  },
  chip: {
    marginTop: '.5rem',
    marginRight: '.5rem',
  }
}));

function BakemarkProductInput(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  async function getProducts() {
    setLoading(true)

    let results
    try {
      results = await axios.get('/v1/bakemark-products/all')
    } catch (err) {
      console.warn("Could not get bakemark products", err)
      setLoading(false)
      return
    }

    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['bakemark_product_name'],
        value: result['bakemark_product_id'],
        code: result['bakemark_product_code'],
        brand: result['bakemark_brand_name'],
      }
    })

    setProducts(normalizedResults)
    setLoading(false)
  }

  const handleInputChange = (newItem) => {
    const index = props.bakemarkProducts.findIndex(obj => obj.value === newItem.value);
    if (index !== -1) {
      return
    }
    const newSelected = [...props.bakemarkProducts, newItem]
    props.setBakemarkProducts(newSelected)
  }
  
  const removeChip = (item) => () => {
    const index = props.bakemarkProducts.findIndex(obj => obj.value === item.value);
    const newSelected = [
      ...props.bakemarkProducts.slice(0, index),
      ...props.bakemarkProducts.slice(index + 1)
    ]
    props.setBakemarkProducts(newSelected)
  }

  useEffect(() => {
    getProducts();
  }, [])

  return (
    <Grid container>
      <Grid item xs={12}>
        {props.bakemarkProducts.map((item) => (
          <Chip key={item.value} onDelete={removeChip(item)} label={`${item.label} (${item.brand}${item.code ? `, ${item.code}` : ""})`} className={classes.chip}/>
        ))}
      </Grid>
      <Grid item xs={11}>
        <AutosuggestAutocomplete
          suggestions={products}
          onChange={handleInputChange}
          disabled={loading}
          field="BakeMark Product"
          loading={loading}
        />
      </Grid>
      <Grid item xs={1} className={classes.buttonAlign}>
        <RefreshButton onClick={getProducts} disabled={loading} />
      </Grid>
    </Grid>
  );
}

export default BakemarkProductInput;
