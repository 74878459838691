import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

function InfoDialog(props) {
  
  const handleRightButton = async () => {
    if (props.onRightButton) {
      await props.onRightButton()
    }
    props.onClose()
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={props.size || "sm"}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {props.children}
      </DialogContent>
      <DialogActions>
        {
          props.leftButton && (
            <Button
              onClick={props.onClose}
              color="primary"
            >
              {props.leftButton}
            </Button>
          )
        }
        <LoadingIndicator loading={props.loading} vertShift="small">
          <Button
            onClick={handleRightButton}
            color="primary"
            disabled={(props.rightButtonDisabled && props.rightButtonDisabled()) || props.loading}
            autoFocus
          >
            {props.rightButton}
          </Button>
        </LoadingIndicator>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;
