import {
  SET_POTENTIAL_PRODUCTS,
  REMOVE_POTENTIAL_PRODUCT,
  SET_INCOMPLETE_INFORMATION,
  ADD_INCOMPLETE_INFORMATION,
  REMOVE_INCOMPLETE_INFORMATION,
  SET_POTENTIAL_CUSTOMERS,
  REMOVE_POTENTIAL_CUSTOMER,
  SET_POTENTIAL_PRICE_INQUIRIES,
  REMOVE_POTENTIAL_PRICE_INQUIRY,
  SET_RESOLVE_CUSTOMER,
  SET_RESOLVE_CUSTOMER_CUSTOMER,
  SET_RESOLVE_CUSTOMER_LOCATION,
} from '../actionTypes';

const resolveCustomerDefaultState = {
  inquiryId: -1,
  customer: { label: "", value: { name: "", code: "" } },
  customerOther: { name: "", code: "" },
  location: {
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  }
}

const potentialAdditionsDefaultState = {
  productData: [],
  incompleteInformationData: [],
  customerData: [],
  priceInquiryData: [],
  resolveCustomer: resolveCustomerDefaultState,
}

const potentialAdditionsReducer = (state = potentialAdditionsDefaultState, action) => {
  switch (action.type) {
    case SET_POTENTIAL_PRODUCTS:
      return {
        ...state,
        productData: action.productData,
      }
    case REMOVE_POTENTIAL_PRODUCT:
      return {
        ...state,
        productData: [
          ...state.productData.slice(0, action.index),
          ...state.productData.slice(action.index + 1)
        ]
      }
    case SET_INCOMPLETE_INFORMATION:
      return {
        ...state,
        incompleteInformationData: action.incompleteInformationData,
      }
    case ADD_INCOMPLETE_INFORMATION:
      return {
        ...state,
        incompleteInformationData: [
          action.newIncompleteInformation,
          ...state.incompleteInformationData
        ]
      }
    case REMOVE_INCOMPLETE_INFORMATION:
      return {
        ...state,
        incompleteInformationData: [
          ...state.incompleteInformationData.slice(0, action.index),
          ...state.incompleteInformationData.slice(action.index + 1)
        ]
      }
    case SET_POTENTIAL_CUSTOMERS:
      return {
        ...state,
        customerData: action.customerData,
      }
    case REMOVE_POTENTIAL_CUSTOMER:
      return {
        ...state,
        customerData: [
          ...state.customerData.slice(0, action.index),
          ...state.customerData.slice(action.index + 1)
        ]
      }
    case SET_POTENTIAL_PRICE_INQUIRIES:
      return {
        ...state,
        priceInquiryData: action.priceInquiryData,
      }
    case REMOVE_POTENTIAL_PRICE_INQUIRY:
      return {
        ...state,
        priceInquiryData: [
          ...state.priceInquiryData.slice(0, action.index),
          ...state.priceInquiryData.slice(action.index + 1)
        ]
      }
    case SET_RESOLVE_CUSTOMER:
      return {
        ...state,
        resolveCustomer: {
          ...resolveCustomerDefaultState,
          inquiryId: action.inquiryId,
          customer: action.customer,
          customerOther: action.customerOther,
          location: {
            ...resolveCustomerDefaultState.location,
            latitude: action.latitude,
            longitude: action.longitude
          }
        }
      }
    case SET_RESOLVE_CUSTOMER_CUSTOMER:
      return action.isOther ? {
        ...state,
        resolveCustomer: {
          ...state.resolveCustomer,
          customerOther: action.customer
        }
      } : {
          ...state,
          resolveCustomer: {
            ...state.resolveCustomer,
            customer: action.customer
          }
        }
    case SET_RESOLVE_CUSTOMER_LOCATION:
      return {
        ...state,
        resolveCustomer: {
          ...state.resolveCustomer,
          location: action.locationData
        }
      }
    default:
      return state;
  }
}

export default potentialAdditionsReducer
