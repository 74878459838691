import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import TwoInputSelect from './TwoInputSelect';
import axios from 'axios';
import { Grid, DialogContentText } from '@material-ui/core';
import RefreshButton from '../RefreshButton/RefreshButton';
import qs from 'querystring';
import InfoDialog from '../InfoDialog/InfoDialog';

const useStyles = makeStyles(theme => ({
  buttonAlign: {
    textAlign: 'center'
  }
}));

function ProductInput(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)

  function handleClose() {
    props.setProduct({
      label: "Other...",
      value: -2
    }, false)
    setAlertOpen(false);
  }

  async function getProducts() {
    if (props.subcategory.value < 0 || props.competitor.value < 0) {
      return
    }

    setLoading(true)
    
    const { bleached, enriched, malted, ascorbicAcid, enzymes, bromated } = props.flourDetails
    let results
    try {
      results = await axios.get('/v1/competitive-products?' + qs.stringify({
        subcategoryId: props.subcategory.value,
        competitiveBrandId: props.competitor.value,
        useFlourDetails: props.category === 23,
        bleached: bleached ? 1 : 0,
        enriched: enriched ? 1 : 0,
        malted: malted ? 1 : 0,
        ascorbicAcid: ascorbicAcid ? 1 : 0,
        enzymes: enzymes ? 1 : 0,
        bromated: bromated ? 1 : 0
      }))
    } catch (err) {
      console.warn("Could not get competitive products", err)
      setLoading(false)
      return
    }
    
    const normalizedResults = results.data['array'].map((result) => {
      return {
        label: result['product_name'],
        value: result['competitive_product_id'],
        code: result['product_code']
      }
    })

    if (normalizedResults.length === 0 && !props.hideDialog) {
      setAlertOpen(true)
    }
    
    setProducts(normalizedResults)
    setLoading(false)
  }
  
  useEffect(() => {
    getProducts()
  }, [props.competitor, props.subcategory, props.flourDetails.bleached, props.flourDetails.enriched, props.flourDetails.malted, props.flourDetails.ascorbicAcid, props.flourDetails.enzymes, props.flourDetails.bromated])

  return (
    <>
      <Grid container>
        <Grid item xs={11}>
          <TwoInputSelect
            selectValue={props.product}
            textValueForName={props.productOther.name}
            textValueForCode={props.productOther.code}
            field="product"
            handleChange={props.setProduct}
            options={products}
            disabled={!props.subcategory.label || !props.competitor.label}
            hideSelect={props.hideSelect}
            loading={loading}
            hint={props.showHint && "Note: This will create a new product"}
          />
        </Grid>
        {
          !props.hideSelect && (
            <Grid item xs={1} className={classes.buttonAlign}>
              <RefreshButton
                onClick={getProducts}
                disabled={!props.subcategory.label || !props.competitor.label || loading}
              />
            </Grid>
          )
        }
      </Grid>
      <InfoDialog open={alertOpen} onClose={handleClose} title="No product found" leftButton="Cancel" rightButton="Enter Manually">
        <DialogContentText>
          No competitive product was found for the given category, subcategory, and competitor. Please enter the product manually.
        </DialogContentText>
      </InfoDialog>
    </>
  );
}

export default ProductInput;
