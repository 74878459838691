import React from 'react';
import { Fade, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styledBy = (property, mapping) => props => mapping[props[property]];
const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -12,
    marginTop: styledBy('vertShift', {
      big: -16,
      small: -12,
      undefined: -8,
    }),
  },
  wrapper: {
    position: 'relative',
  },
}))

function LoadingIndicator(props) {
  const classes = useStyles(props)
  
  return (
    <div className={classes.wrapper}>
      {props.children}
      <Fade
        in={props.loading}
        style={{
          transitionDelay: props.loading ? '800ms' : '0ms',
        }}
        unmountOnExit
        className={classes.progress}
      >
        <CircularProgress size={24} />
      </Fade>
    </div>
  )
}

export default LoadingIndicator;
