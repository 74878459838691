import React, { useState, useEffect } from 'react';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import axios from 'axios'
import qs from 'querystring'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  imageRender: {
    width: '100%',
    height: '100%',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    transition: 'transform 0.2s',
  },
}));

function InquiryPictures(props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [pictures, setPictures] = useState([])

  const getPictures = async () => {
    setLoading(true)
    const results = await axios.get('/v1/pictures?' + qs.stringify({
      submittedInquiryId: props.submittedInquiryId
    }))

    const formattedResult = results.data['array'].map((arrItem) => {
      return {
        pictureId: arrItem['picture_id'],
        pictureUrl: arrItem['picture_url'],
      }
    })

    setPictures(formattedResult)
    setLoading(false)
  }

  useEffect(() => {
    getPictures()
  }, [])

  return (
    <LoadingIndicator loading={loading}>
      {pictures.map((picture) => (
        <a href={picture.pictureUrl} target="_blank" rel="noopener noreferrer" key={picture.pictureId}>
          <img src={picture.pictureUrl} className={classes.imageRender} alt={"Submitted inquiry photo #" + picture.pictureId} />
        </a>
      ))}
      {pictures.length === 0 && <p>No pictures submitted with inquiry</p>}
    </LoadingIndicator>
  );
}

export default InquiryPictures
