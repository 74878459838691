import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

function renderInputComponent(inputProps) {
  const { classes, disabled, loading, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <LoadingIndicator loading={loading}>
      <TextField
        fullWidth
        required
        variant="outlined"
        margin="normal"
        disabled={disabled}
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
        }}
        {...other}
      />
    </LoadingIndicator>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const displayText = `${suggestion.label} (${suggestion.brand}${suggestion.code ? `, ${suggestion.code}` : ""})`
  const matches = match(displayText, query);
  const parts = parse(displayText, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ),
        )}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value, suggestions) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 && (suggestion.label.slice(0, inputLength).toLowerCase() === inputValue || 
          suggestion.code.slice(0, inputLength).toLowerCase() === inputValue)

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function getSuggestionValue(suggestion) {
  return suggestion;
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
});

function AutosuggestAutocomplete(props) {
  const [selected, setSelected] = React.useState('')
  const [suggestions, setSuggestions] = React.useState([])

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, props.suggestions))
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  const handleChange = (event, { newValue, method }) => {
    if (method === "click") {
      props.onChange(newValue)
      setSelected('')
    } else {
      setSelected(newValue)
    }
  };

  const { classes } = props;

  const autosuggestProps = {
    renderInputComponent,
    suggestions: suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          label: props.field,
          disabled: props.disabled,
          value: selected,
          onChange: handleChange,
          loading: props.loading,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

AutosuggestAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AutosuggestAutocomplete);
