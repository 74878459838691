import React, { useState } from 'react';
import { Typography, Button, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { getInquiryResults } from '../CreateMatchInquiry/matchInquiryFunctions';
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  subText: {
    fontWeight: 400
  },
  marginTop: {
    marginTop: theme.spacing(0.5)
  },
  details: {
    display: 'block'
  },
  verticallyCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
}));

function InquiryExpansionPanel(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleShowResults = (inquiry) => async () => {
    setLoading(true)
    const inquiryResults = await getInquiryResults(inquiry.product.id)
    props.history.push({ pathname: '/inquiry/match/results', state: { results: inquiryResults } })
    setLoading(false)
  }

  return (
    <>
      {props.inquiries.map((inquiry, index) => (
        <ExpansionPanel expanded={expanded === index} onChange={handleChange(index)} key={index}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography variant="h5">
                  {inquiry.product.label}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.verticallyCenter}>
                <Typography className={classes.secondaryHeading}>
                  {moment.utc(inquiry.date).local().format('MMM Do YYYY, h:mm a')}
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {inquiry.product.code && (
              <Typography variant="h6" className={classes.subText}>
                #{inquiry.product.code}
              </Typography>
            )}
            <Typography variant="h6" className={classes.subText}>
              {inquiry.brand.label}
            </Typography>
          </ExpansionPanelDetails>
          {inquiry.product.id && (
            <ExpansionPanelActions>
              <LoadingIndicator loading={loading} vertShift="small">
                <Button size="small" color="primary" onClick={handleShowResults(inquiry)} disabled={loading}>
                  Results
                </Button>
              </LoadingIndicator>
            </ExpansionPanelActions>
          )}
        </ExpansionPanel>
      ))}
    </>
  );
}

export default withRouter(InquiryExpansionPanel)
