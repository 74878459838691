import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  mainButton: {
    marginBottom: theme.spacing(1),
  }
}));

function SubmitButton(props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  
  async function handleClick() {
    if (loading) {
      return
    }

    if (props.onClick) {
      setLoading(true)
      const newProps = await props.onClick()
      setLoading(false)
      var { newPath, newState } = newProps || {}
    }

    if (!props.noRedirect) {
      window.scrollTo(0, 0)
      props.history.push({ pathname: newPath || props.path, state: newState || props.state });
    }
  }

  return (
    <LoadingIndicator loading={loading} vertShift="small">
      <Button
        fullWidth
        variant="contained"
        size="large"
        color={props.color || "primary"}
        onClick={handleClick}
        disabled={(props.disabled && props.disabled()) || loading}
        className={clsx(classes.mainButton, props.className)}
      >
        {props.children}
      </Button>
    </LoadingIndicator>
  )
}

export default withRouter(SubmitButton);
