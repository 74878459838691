import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import SubmitButton from '../SubmitButton/SubmitButton';
import TextAppBar from '../TextAppBar/TextAppBar';
import PictureForm from '../PictureForm/PictureForm';
import NetWeightInput from '../InquirySelect/NetWeightInput';
import PriceInput from '../InquirySelect/PriceInput';
import { writeInquiryToLS, createInquirySubmission, isAddlFormInvalid } from '../CreateMatchInquiry/matchInquiryFunctions'
import axios from 'axios';
import { SET_MATCH_INQUIRY_NET_WEIGHT, SET_MATCH_INQUIRY_UNIT_OF_MEASURE, SET_MATCH_INQUIRY_PICTURES, SET_MATCH_INQUIRY_PRICE } from '../../store/actionTypes';

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.user,
    inquiryForm: state.matchInquiryForm,
    netWeight: state.matchInquiryForm.netWeight,
    unitOfMeasure: state.matchInquiryForm.unitOfMeasure,
    price: state.matchInquiryForm.price,
    pictures: state.matchInquiryForm.pictures,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setNetWeight: (netWeight) => {
      dispatch({
        type: SET_MATCH_INQUIRY_NET_WEIGHT,
        netWeight
      })
    },
    setUnitOfMeasure: (unitOfMeasure) => {
      dispatch({
        type: SET_MATCH_INQUIRY_UNIT_OF_MEASURE,
        unitOfMeasure
      })
    },
    setPictures: (pictures) => {
      dispatch({
        type: SET_MATCH_INQUIRY_PICTURES,
        pictures
      })
    },
    setPrice: (price) => {
      dispatch({
        type: SET_MATCH_INQUIRY_PRICE,
        price
      })
    },
  }
}

function AdditionalInfo(props) {

  const handleSubmit = async () => {
    let newPath = "/"
    let newState = {}
    let connectionError = false
    const submissionData = createInquirySubmission(props.inquiryForm, props.authUser)

    try {
      await axios.put('/v1/inquiry', {
        inquiryData: submissionData
      })
    } catch (err) {
      console.warn("Could not submit additional info,", err)
      connectionError = true
    }

    if (connectionError) {
      writeInquiryToLS(props.inquiryForm)
      newState = { event: "CONNECTION_ERROR" }
    } else {
      newState = { event: "ADDITIONAL_INFO_SUBMITTED" }
    }

    return {
      newPath,
      newState
    }
  }

  const verifyForm = () => {
    return isAddlFormInvalid(props.inquiryForm)
  }

  return (
    <>
      <TextAppBar>Additional Information Required</TextAppBar>
      <Grid container justify="space-between">
        <Grid item xs={12}>
          <p>No equivalent BakeMark product was found, please provide more information so we can improve our results.</p>
        </Grid>
      </Grid>
      <NetWeightInput
        netWeight={props.netWeight}
        unitOfMeasure={props.unitOfMeasure}
        setNetWeight={props.setNetWeight}
        setUnitOfMeasure={props.setUnitOfMeasure}
        required
        fullWidth
      />
      <PictureForm required pictures={props.pictures} setPictures={props.setPictures}>Add picture of label</PictureForm>
      <PriceInput price={props.price} setPrice={props.setPrice}/>
      <p>Please also attempt to collect:</p>
      <ul>
        <li>Spec sheet</li>
        <li>Sample</li>
      </ul>
      <SubmitButton onClick={handleSubmit} disabled={verifyForm}>Submit</SubmitButton>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfo);
